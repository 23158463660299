<mat-card class="ogp-faq-container">
    <mat-card-header>
        <mat-card-title>
            <h3 data-cy="user-guidance-title">{{ faqSectionTitle | translate }}</h3>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let faqItem of faqItems">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ faqItem.questionKey | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <span [innerHTML]="faqItem.answerKey | translate "></span>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>