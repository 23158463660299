<br />
<h2>{{ "volitions.expressYourWill" | translate }}</h2>
<br />
<mat-card>
  <div class="ob-grid">
    <div class="ob-full">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <mat-radio-group formControlName="donorType">
          <div *ngFor="let donorType of donorTypes">
            <mat-radio-button [id]="donorType" [value]="donorType">
              {{ "volitions." + donorType | translate }}
              <mat-error
                *ngIf="
                  showNoBodyPartsSelectedErrorMessage &&
                  donorType === 'PARTIAL_DONOR'
                "
              >
                <br />
                {{ "volitions.selectAtLeastOneBodyPart" | translate }}
              </mat-error>
            </mat-radio-button>
            <div
              *ngIf="donorType === 'PARTIAL_DONOR'"
              formArrayName="bodyParts"
            >
              <div
                class="body-parts"
                *ngFor="let bodyPart of getBodyPartsArray(); let i = index"
                [formGroup]="bodyPart"
              >
                <mat-checkbox formControlName="isSelected">
                  {{ "bodyParts." + getBodyPartName(bodyPart) | translate }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </form>
      <div class="ob-full">
        <button
          (click)="onSubmit()"
          mat-button
          obButton="primary"
          type="submit"
        >
          {{ "publish" | translate }}
        </button>
      </div>
    </div>
  </div>
</mat-card>
