import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from '@angular/common';
import { EmailValidatorDirective } from '../validators/email-validator.directive';
import { MatButton } from '@angular/material/button';
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle
} from '@angular/material/card';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ObButtonDirective, ObExternalLinkDirective, ObFormFieldDirective, ObSelectDirective } from '@oblique/oblique';
import { PhoneNumberValidatorDirective } from '../validators/phone-number-validator.directive';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MailVerificationInformationDto, PersonDto } from "@ogreg/shared";
import { MatCheckbox } from '@angular/material/checkbox';

import phone_codes from '../../../assets/phone_codes.json';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { ContactDetailsService } from './contact-details.service';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { Subscription } from 'rxjs';
import { EmailVerificationService } from "../../services/http-services/email-verification.service";


@Component({
  selector: 'ogp-contact-details',
  standalone: true,
  imports: [CommonModule, EmailValidatorDirective, MatButton, MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle, MatError, MatFormField, MatInput, MatLabel, MatSelect, MatSelectModule, ObButtonDirective, ObFormFieldDirective, PhoneNumberValidatorDirective, ReactiveFormsModule, TranslateModule, MatCheckbox, FormsModule, ObSelectDirective, MatOption, MatIcon, ObExternalLinkDirective, EmailVerificationComponent],
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ContactDetailsComponent implements OnInit, OnChanges {
  @Input() eIamPerson!: PersonDto;
  @Input() person: PersonDto | null = null;
  @Input() currentVerification: MailVerificationInformationDto | undefined;
  @Output() readonly personEdited: EventEmitter<PersonDto> = new EventEmitter<PersonDto>();
  @Output() readonly emailReset: EventEmitter<boolean> = new EventEmitter<boolean>();
  public formGroup!: FormGroup;
  emailValueChangesSubscription: Subscription | undefined;
  formSubmitted = false;

  constructor(private readonly contactDetailsService: ContactDetailsService,
              private readonly formBuilder: FormBuilder,
              private readonly emailVerificationService: EmailVerificationService,
              private readonly el: ElementRef) {
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    if (!event.defaultPrevented) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  ngOnInit() {
    this.formGroup = this.getFormGroup((this.person?.id) ? this.person : this.eIamPerson);
    if (this.person?.email || this.currentVerification?.needsMailVerification) {
      this.formGroup.disable();
    } else {
      this.enableFormGroup();
    }
    this.subscribeToEmailValueChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['currentVerification']) {
      if (this.currentVerification?.needsMailVerification) {
        this.formGroup = this.getFormGroup((this.person?.id) ? this.person : this.eIamPerson);
        this.formGroup.disable();
      }
    }
  }

  toggleEnableFormGroup() {
    if (this.formGroup.disabled) {
      this.enableFormGroup();
    } else {
      this.formGroup.disable();
    }
  }
  getPhoneCodes(): string[] {
    return phone_codes;
  }

  getFormGroup(person: PersonDto | undefined) {
    const telephonePrefix = this.getPhonePrefix(person?.telephoneNumber);
    const mobilePrefix = this.getPhonePrefix(person?.mobileNumber);
    const telephoneNumberWithoutPrefix = person?.telephoneNumber?.substring(telephonePrefix?.length ?? 0);
    const mobileNumberWithoutPrefix = person?.mobileNumber?.substring(mobilePrefix?.length ?? 0);
    const formGroup = this.formBuilder.group({
      telephoneCode: [telephonePrefix ?? '+41'],
      telephoneNumber: [telephoneNumberWithoutPrefix],
      mobileCode: [mobilePrefix ?? '+41'],
      mobileNumber: [mobileNumberWithoutPrefix],
      email: [(this.currentVerification?.forMail) ? this.currentVerification?.forMail : person?.email, Validators.required],
      emailRepeat: [(this.currentVerification?.forMail) ? this.currentVerification?.forMail : person?.email, Validators.required],
      acceptedLegalText: [(person?.acceptedLegalText) ? person?.acceptedLegalText : false, Validators.requiredTrue]
    });
    formGroup.get('emailRepeat')?.setValidators(this.emailConfirmValidator(formGroup, 'email'));
    if (!formGroup.get('email')?.value) {
      formGroup.get('acceptedLegalText')?.setValue(false);
    }


    return formGroup;
  }

  getPhonePrefix(phoneNumber: string | undefined) {
    if (!phoneNumber)
      return undefined;

    const phoneCodes = this.getPhoneCodes();
    let longestPrefix = '';
    for (const code of phoneCodes) {
      if (phoneNumber.startsWith(code) && code.length > longestPrefix.length) {
        longestPrefix = code;
      }
    }

    return longestPrefix;
  }

  emailConfirmValidator(formGroup: FormGroup, fieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value != formGroup.controls[fieldName].value) {
        return { mailNotTheSame: true };
      }
      return null;
    };
  }

  reset() {
    this.formGroup = this.getFormGroup(this.person ?? undefined);
    if (this.formGroup.get('email')?.value) {
      this.formGroup.disable();
    }
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.formGroup.invalid) {
      this.showNotification();
      this.setFocusOnFirstElementThatHasError();
      return;
    }
    const personDto: PersonDto = {
      id: this.eIamPerson.id,
      firstName: this.eIamPerson.firstName,
      lastName: this.eIamPerson.lastName,
      birthDate: this.eIamPerson.birthDate,
      nationality: this.eIamPerson.nationality,
      placeOfBirth: this.eIamPerson.placeOfBirth,
      homeTown: this.eIamPerson.homeTown,
      socialSecurityNumber: this.eIamPerson.socialSecurityNumber,
      email: this.formGroup?.value?.email,
      telephoneNumber: this.contactDetailsService.getMergedTelephoneNumber(this.formGroup?.value?.telephoneCode, this.formGroup?.value?.telephoneNumber),
      mobileNumber: this.contactDetailsService.getMergedMobileNumber(this.formGroup?.value?.mobileCode, this.formGroup?.value?.mobileNumber),
      acceptedLegalText: this.formGroup?.value?.acceptedLegalText
    };
    if (this.formGroup.dirty) {
      this.personEdited.emit(personDto);
    }
  }

  setFocusOnFirstElementThatHasError() {
    for (const key of Object.keys(this.formGroup.controls)) {
      if (this.formGroup.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }

  showNotification() {
    if (this.formGroup.get('telephoneNumber')?.errors?.['phoneNumberValidator'] || this.formGroup.get('mobileNumber')?.errors?.['phoneNumberValidator']) {
      this.contactDetailsService.showWarningNotification(
        'notifications.warningTitle',
        'notifications.invalidTelephoneNumberFormat'
      );
    }
    if (this.formGroup.get('email')?.errors?.['mailNotTheSame'] || this.formGroup.get('emailRepeat')?.errors?.['mailNotTheSame']) {
      this.contactDetailsService.showWarningNotification(
        'notifications.warningTitle',
        'personRegister.contactDetails.confirmationMailNotTheSame'
      );
    }
    if (this.formGroup.get('email')?.errors?.['emailValidator'] || this.formGroup.get('emailRepeat')?.errors?.['emailValidator']) {
      this.contactDetailsService.showWarningNotification(
        'notifications.warningTitle',
        'notifications.invalidEmailFormat'
      );
    }
    if (!this.formGroup.get('acceptedLegalText')?.value) {
      this.contactDetailsService.showWarningNotification(
        'notifications.warningTitle',
        'notifications.acceptedLegalText'
      );
    }
  }

  subscribeToEmailValueChanges() {
    this.emailValueChangesSubscription = this.formGroup.get('email')?.valueChanges.subscribe(() => {
      this.formGroup.get('emailRepeat')?.setValue('');
      this.formGroup.get('acceptedLegalText')?.setValue('');
    });
  }

  enableFormGroup() {
    // Unsubscribe before enabling the form group to prevent valueChanges execution
    if (this.emailValueChangesSubscription) {
      this.emailValueChangesSubscription.unsubscribe();
    }

    // Enable the form group
    this.formGroup.enable();

    // Re-subscribe after enabling the form group
    this.subscribeToEmailValueChanges();
  }

  onEmailReset(success: boolean) {
    this.emailReset.emit(success);
  }
}
