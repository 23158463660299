<div *ngIf="volition$ | async as volition">
  <div *ngIf="bodyParts$ | async as bodyParts">
    <ogp-volition-form
      [volition]="volition"
      [donorTypes]="donorTypes"
      [bodyParts]="bodyParts"
      (volitionChange)="onVolitionChange($event)"
    >
    </ogp-volition-form>
  </div>
</div>
