<div class="grid-container">
  <div class="header">
    <div class="ogr-breadcrumb hide-smaller-than-md">
      <a [routerLink]="'/home'" data-cy="home-page-breadcrumb-link">
        {{ "homePage.title" | translate }}
      </a>
      <mat-icon class="ob-icon" [svgIcon]="'chevron-small-right'"></mat-icon>
      {{ "personRegister.title" | translate }}
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1>{{ "personRegister.title" | translate }}</h1>
        </div>
        <div class="col-lg-6 shown-md">
                <mat-slide-toggle
                  id="user-guide-slide-toggle-md"
                  labelPosition="after"
                  data-cy="user-guide-slide-toggle-md"
                  (click)="toggleUserGuideMd()">
                  {{ ((userGuideOpenMd) ? "personRegister.hideUserGuideMenu" : "personRegister.showUserGuideMenu") | translate }}
                </mat-slide-toggle>
        </div>
        <div class="col-lg-6 flex-container-ogr shown-lg hide-smaller-than-md">
            <div class="pull-right">
                <mat-slide-toggle
                        id="user-guide-slide-toggle-lg"
                        labelPosition="before"
                        data-cy="user-guide-slide-toggle-lg"
                        [checked]="userGuideOpenLg"
                        (click)="toggleUserGuideLg()">
                    {{ ((userGuideOpenLg) ? "personRegister.hideUserGuideMenu" : "personRegister.showUserGuideMenu") | translate }}
                </mat-slide-toggle>
            </div>
        </div>
        </div>
    </div>
  </div>
  <div class="main" *ngIf="eIamPerson">
    <div *ngIf="!userGuideOpenMd">
      <ogp-personal-data [eIamPerson]="eIamPerson"></ogp-personal-data>
      <div *ngIf="person$ | async as person">
        <ogp-contact-details
                             [eIamPerson]="eIamPerson"
                             [person]="person"
                             [currentVerification]="currentVerification"
                             (personEdited)="(person.id)?onPersonEdited($event):onPersonCreated($event)"
                             (emailReset)="onEmailReset($event)">
        </ogp-contact-details>
      </div>
    </div>
  </div>
  <div *ngIf="userGuideOpenLg"
          class="right user-guidance hide-smaller-than-md">
    <ogp-user-guidance-person-register></ogp-user-guidance-person-register>
  </div>
  <div class="footer">
  </div>
</div>
<div *ngIf="userGuideOpenMd"
     class="hide-bigger-than-md user-guidance-md">
  <ogp-user-guidance-person-register>
  </ogp-user-guidance-person-register>
</div>
<div class="jumbotron-gray hide-smaller-than-sm">
  <ogp-help></ogp-help>
</div>
