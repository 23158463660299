import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {
    CardOption,
    OgpCardSelectionComponent
} from "../../../shared/components/ogp-card-selection/ogp-card-selection.component";
import {NgForOf} from "@angular/common";
import {OgpCardContentComponent} from "../../../shared/components/ogp-card-content/ogp-card-content.component";
import {DonorOption, getDonorOptionHoverColor, NavigationStepData, VolitionService} from "../../volition.service";


@Component({
    selector: 'ogp-donor-option-step',
    templateUrl: './donor-option-step.component.html',
    standalone: true,
    imports: [
        TranslateModule,
        OgpCardSelectionComponent,
        NgForOf,
        OgpCardContentComponent,
    ]
})
export class DonorOptionStepComponent implements OnInit {
    @Output() activeStepChanged: EventEmitter<NavigationStepData> = new EventEmitter<NavigationStepData>();
    protected donorCardOptions!: CardOption[];
    protected donorOptions: DonorOption[] = ['NON_DONOR', 'DONOR', 'TRUSTED_PERSON'];

    constructor(private volitionService: VolitionService) {
    }

    public ngOnInit(): void {
        this.donorCardOptions = this.donorOptions.map(donorOption => {
            return {
                cardId: donorOption.toLowerCase(),
                cardTitleKey: `volitions.decisionStep.${donorOption}.title`,
                cardInformationKey: `volitions.decisionStep.${donorOption}.information`,
                headerIconNameKey: `volitions.decisionStep.${donorOption}.headerIconName`,
                cardHoverColor: getDonorOptionHoverColor(donorOption),
                headerIconAlt: `volitions.decisionStep.${donorOption}.headerIconAlt`,
                optionKey: donorOption,
                cursorPointer: true
            }
        })
    }

    handleCardSelection(optionKey: DonorOption): void {
        this.volitionService.setDonationVolitionData("lastCompletedStep", "donorOptionStep");
        this.volitionService.setDonationVolitionData("decision", optionKey);
        this.activeStepChanged.emit({
            activeStep: "DECISION_STEP",
            activeSubStep: "myChoiceStep",
            lastCompletedStep: "donorOptionStep"
        });
    }

}