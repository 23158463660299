<mat-vertical-stepper
                      [selectedIndex]="currentStep"
                      [linear]="true"
                      [class.mat-stepper-lg]="true"
                      [disableRipple]="true">
    <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="pen"/>
    </ng-template>

    <ng-template matStepperIcon="done">
        <mat-icon svgIcon="checkmark"/>
    </ng-template>

    <mat-step *ngFor="let step of steps; let index = index">
        <ng-template matStepLabel>
                {{ step }}
        </ng-template>
    </mat-step>
</mat-vertical-stepper>
