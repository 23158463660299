<div class="grid-container max-width-content">
    <div class="header ogp-container">
        <ogp-breadcrumb [options]="breadcrumbOptions"></ogp-breadcrumb>
        <div class="row">
            <div class="col-12">
                <h1>{{ "volitions.title" | translate }}</h1>
            </div>
            <div class="col-12">
                <p>{{ "volitions.information" | translate }}</p>
            </div>
        </div>
    </div>
    <div class="left margin-l-xs">
        <ogp-stepper [steps]="stepperNames"></ogp-stepper>
    </div>
    <div class="main">
        <ogp-donor-option-step *ngIf="stepData.activeSubStep === 'donorOptionStep'"
                               (activeStepChanged)="handleActiveStepChanged($event)">
        </ogp-donor-option-step>
        <ogp-my-choice-step *ngIf="stepData.activeSubStep === 'myChoiceStep'"
                            [myActiveChoice]="stepData.data"
                            (activeStepChanged)="handleActiveStepChanged($event)">
        </ogp-my-choice-step>
    </div>
    <div class="right">
        <ogp-faq-section [faqSectionTitle]="'Hilfestellung'"></ogp-faq-section>
    </div>
</div>
<div class="jumbotron-gray margin-t-2xl">
    <ogp-help></ogp-help>
</div>
