<ogp-card-selection [cardOption]="donorCardOption">
</ogp-card-selection>
<div class="margin-t-l margin-b-15">
    {{"volitions.myChoiceStep.donor.donorInformation.prefix" | translate}}
    <a class="button-link"
       tabindex="0"
       data-cy="donor-information-dialog-link"
       (click)="openDialog()"
       (keydown)="onDonorInformationEnterKeyPressed($event)">
        {{"volitions.myChoiceStep.donor.donorInformation.link" | translate}}</a>{{"volitions.myChoiceStep.donor.donorInformation.suffix" | translate}}
</div>
<div class="ogp-flex-block ogp-flex-block-direction-column">
    <div class=ogp-flex-block-items-row-gap>
        <mat-radio-group [(ngModel)]="selectedDonorType"
                         (change)="onSelectionChange($event)"
                         data-cy="donor-radio-group">
            <mat-radio-button *ngFor="let donorItem of donorRadioItems"
                              [value]="donorItem.value"
                              [id]="donorItem.value"
                              [attr.data-cy]="'radio-item-' + donorItem.value">
                <div [innerHTML]="donorItem.label | translate"></div>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <ng-container *ngIf="selectedDonorType === DonorOptionEnum.PARTIAL_DONOR">
        <mat-divider></mat-divider>
        <ogp-body-parts-selection [selectedBodyParts]="selectedBodyParts"
                                  (updateBodyPartSelection)="updateBodyPartSelection($event)">
        </ogp-body-parts-selection>
    </ng-container>
</div>



<!-- REMOVE WHOLE COMPONENT
<ogp-volition-form [bodyParts]="" ></ogp-volition-form> -->