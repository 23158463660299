<div class="max-width-content">
    <h1 class="margin-b-l">{{ "homePage.title" | translate }}</h1>
    <div class="flex-container-ogr">
        <mat-card class="flex-item-ogr margin-right hover card-container margin-b-l"
                  tabindex="0"
                  (click)="navigateTo('person-register')"
                  (keydown)="onContactDetailsEnterKeyPressed($event)"
                  data-cy="contact-details-card">
            <mat-card-header>
                <mat-card-title>
                    <h2>
                        {{ "homePage.contactDetails.title" | translate }}
                    </h2>
                </mat-card-title>
                <mat-card-subtitle><b>{{ "homePage.contactDetails.subtitle" | translate }}</b></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                {{ "homePage.contactDetails.text" | translate }}
            </mat-card-content>
            <mat-card-actions class="sticky-actions">
                <mat-chip [class]="contactDataCompleted? 'success' : 'warning'"
                          data-cy="contact-details-card-status-chip">
                    <ng-container *ngIf="contactDataCompleted else contactDataNotCompleted">
                        {{ "homePage.contactDetails.status.valueDone" | translate }}
                    </ng-container>
                    <ng-template #contactDataNotCompleted>
                        {{ "homePage.contactDetails.status.valueOpen" | translate }}
                    </ng-template>
                </mat-chip>
                <label>
                    {{ "homePage.contactDetails.status.label" | translate }}:
                </label>
            </mat-card-actions>
        </mat-card>
        <mat-card class="flex-item-ogr margin-right flex-item-last hover card-container margin-b-l"
                  tabindex="0"
                  (click)="navigateTo('volition')"
                  (keydown)="onVolitionEnterKeyPressed($event)"
                  data-cy="expression-of-will-card">
            <mat-card-header>
                <mat-card-title>
                    <h2>
                        {{ "homePage.expressionOfWill.title" | translate }}
                    </h2>
                </mat-card-title>
                <mat-card-subtitle><b>{{ "homePage.expressionOfWill.subtitle" | translate }}</b></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                {{ "homePage.expressionOfWill.text" | translate }}
            </mat-card-content>
            <mat-card-actions class="sticky-actions">
                <mat-chip [class]="volitionCompleted? 'success' : 'warning'"
                          data-cy="expression-of-will-status-chip">
                    <ng-container *ngIf="volitionCompleted else volitionNotCompleted">
                        {{ "homePage.expressionOfWill.status.valueDone" | translate }}
                    </ng-container>
                    <ng-template #volitionNotCompleted>
                        {{ "homePage.expressionOfWill.status.valueOpen" | translate }}
                    </ng-template>
                </mat-chip>
                <label>
                    {{ "homePage.expressionOfWill.status.label" | translate }}:
                </label>
            </mat-card-actions>
        </mat-card>
        <div class="flex-item-ogr hidden-lg">
            <img [alt]="'homePage.imgAlt' | translate"
                 ngSrc="../../assets/images/heart.png"
                 [width]="448"
                 [height]="280"
                 priority />
        </div>
    </div>
</div>
