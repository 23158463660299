import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = () => {
  return sessionStorage.getItem('loggedIn') ? true : showLoginScreen();
};
const showLoginScreen = () => {
  void inject(Router).navigate([`login`]);
  return false;
};
