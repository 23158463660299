<div class="contact-details-container">
  <mat-card class="contact-details" id="contact-details">
    <mat-card-header>
      <mat-card-title>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <h4>{{ "personRegister.contactDetails.title" | translate }}</h4>
          <button mat-button
                  obButton="secondary"
                  type="button"
                  [disabled]="formGroup.enabled || person?.mailVerificationInformation?.needsMailVerification"
                  (click)="toggleEnableFormGroup()"
                  data-cy="change-contact-data-button">
            <mat-icon class="ob-icon" svgIcon="pen" />
            <span class="hidden-lg">{{ "personRegister.contactDetails.changeContactData" | translate }}</span>
          </button>
        </div>
      </mat-card-title>
      <mat-card-subtitle [innerHTML]="'personRegister.contactDetails.subtitle' | translate"></mat-card-subtitle>
    </mat-card-header>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <mat-card-content>
        <div class="container">
          <div class="row">
            <div class="col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "personRegister.contactDetails.person.phonePrefix" | translate }}
                </mat-label>
                <mat-select formControlName="telephoneCode"
                            data-cy="phonePrefix-select">
                  <mat-option [value]="phone_code"
                              *ngFor="let phone_code of getPhoneCodes()"
                              data-cy="telephoneCode-option">
                    {{ phone_code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "personRegister.contactDetails.person.telephoneNumber" | translate }}
                </mat-label>
                <input matInput
                       formControlName="telephoneNumber"
                       type="tel"
                       ogpPhoneNumberValidator
                       data-cy="telephoneNumber-input"/>
                <mat-hint class="telephone-example">
                  {{ (this.formGroup.get('telephoneCode')?.value === '+41') ? "Beispiel: 44 xxx xx xx" : "" }}
                </mat-hint>
                <mat-error *ngIf="this.formGroup.get('telephoneNumber')!.errors?.['phoneNumberValidator']">
                  Beispiel: 44 xxx xx xx
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>{{ "personRegister.contactDetails.person.phonePrefix" | translate }}</mat-label>
                <mat-select formControlName="mobileCode"
                            data-cy="mobileCode-select">
                  <mat-option [value]="phone_code"
                              *ngFor="let phone_code of getPhoneCodes()"
                              data-cy="mobileCode-option">
                    {{ phone_code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "personRegister.contactDetails.person.mobileNumber" | translate }}
                </mat-label>
                <input matInput
                       formControlName="mobileNumber"
                       type="tel"
                       ogpPhoneNumberValidator
                       data-cy="mobileNumber-input"/>
                <mat-hint class="telephone-example">
                  {{ (this.formGroup.get('mobileCode')?.value === '+41') ? "Beispiel: 79 xxx xx xx" : "" }}
                </mat-hint>
                <mat-error *ngIf="this.formGroup.get('mobileNumber')!.errors?.['phoneNumberValidator']">
                  Beispiel: 79 xxx xx xx
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row custom-padding">
            <div class="col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ "personRegister.contactDetails.person.email" | translate }}</mat-label>
                <input matInput formControlName="email" ogpEmailValidator data-cy="email-input" />
                <mat-error *ngIf="this.formGroup.get('email')!.errors?.['emailValidator']">
                  {{ "notifications.invalidEmailFormat" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ "personRegister.contactDetails.person.emailRepeat" | translate }}</mat-label>
                <input matInput formControlName="emailRepeat" ogpEmailValidator data-cy="emailRepeat-input" />
                <mat-error *ngIf="this.formGroup.get('emailRepeat')!.errors?.['mailNotTheSame']">
                  {{ "personRegister.contactDetails.confirmationMailNotTheSame" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <input type="checkbox" formControlName="acceptedLegalText" id="acceptedLegalText" class="form-check-input"
                     [ngClass]="{ 'is-invalid': formSubmitted && this.formGroup.get('acceptedLegalText')!.errors?.['required'] }"
                     data-cy="acceptedLegalText-checkbox"/>
              <label for="acceptedLegalText"
                     [ngClass]="{ 'is-invalid': formSubmitted && this.formGroup.get('acceptedLegalText')!.errors?.['required'] }">
                {{ "personRegister.contactDetails.agreedToReceiveEmailsText" | translate }}
              </label>
            </div>
          </div>
          <div class="row" *ngIf="currentVerification?.needsMailVerification">
            <div class="ob-span-12 custom-padding">
              <ogp-email-verification (emailReset)="onEmailReset($event)"></ogp-email-verification>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="container">
        <div class="row">
          <div class="col-lg-12">
            <button mat-button
                    obButton="tertiary"
                    [disabled]="!this.formGroup.dirty"
                    (click)="reset()"
                    data-cy="reset-button">
              {{ "reset" | translate }}
            </button>
            <button type="submit"
                    mat-button
                    [disabled]="!this.formGroup.dirty"
                    obButton="primary"
                    autofocus
                    data-cy="save-button">
              {{ "saveAndVerify" | translate }}
            </button>
          </div>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
  <div id="scroll-to-contact-details"></div>
</div>
