import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle
} from '@angular/material/card';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PersonsService } from "../../services/http-services/persons.service";
import { VolitionsService } from "../../services/http-services/volitions.service";
import { LoginService } from "@ogreg/ogreg-login";

@Component({
  selector: 'ogp-home-quick-access',
  standalone: true,
  imports: [CommonModule, MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle, NgOptimizedImage, TranslateModule, MatChip, MatChipSet],
  templateUrl: './home-quick-access.component.html',
  styleUrl: './home-quick-access.component.scss'
})
export class HomeQuickAccessComponent implements OnInit {

  contactDataCompleted = false
  volitionCompleted = false

  constructor(private readonly router: Router,
              private readonly personsService: PersonsService,
              private readonly volitionService: VolitionsService,
              private readonly loginService: LoginService,
  ) {
  }

  ngOnInit() {
    if (this.loginService.isLoggedIn()) {
      this.personsService.isPersonalDataCompleted().subscribe(dataCompleted => {
        if (dataCompleted.completed != undefined) {
          this.contactDataCompleted = dataCompleted.completed;
        }
      })

      this.volitionService.isVolitionCompleted().subscribe(dataCompleted => {
        if (dataCompleted.completed != undefined) {
          this.volitionCompleted = dataCompleted.completed;
        }
      })
    }

    this.loginService.logOutEvent.subscribe(() => {
      this.contactDataCompleted = false;
      this.volitionCompleted = false;
    })
  }

  onContactDetailsEnterKeyPressed(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.navigateTo('person-register');
    }
  }

  onVolitionEnterKeyPressed(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.navigateTo('volition');
    }
  }

  navigateTo(routeName: string) {
    this.router.navigate([routeName]);
  }
}
