<mat-card>
    <mat-card-header>
        <div class="header-content block">
            <div class="text-content">
                <mat-card-title [ngClass]="'full-width'" *ngIf="cardTitle !== null">
                    <h2>{{ cardTitle | translate }}</h2>
                </mat-card-title>
                <mat-card-subtitle *ngIf="cardInformation !== null">
                    {{ cardInformation | translate }}
                </mat-card-subtitle>
            </div>
            <div *ngIf="buttonLabel !== null" class="button-container">
                <button (click)="onButtonClick()"
                        mat-button
                        obButton="secondary"
                        type="button">
                    {{ buttonLabel | translate }}
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>
</mat-card>