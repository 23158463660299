import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {NgForOf, NgIf} from "@angular/common";
import {ObButtonDirective, ObCheckboxDirective, ObPopoverModule} from "@oblique/oblique";
import {
    CardOption,
    OgpCardSelectionComponent
} from "../../../../../shared/components/ogp-card-selection/ogp-card-selection.component";
import {
    BodyPartSelectionChoice,
    mapBodyPartDtosToBodyPartSelectionChoices,
    VolitionService
} from "../../../../volition.service";
import {BodyPartDto} from "../../../../../generated/model/modelsQuery";
import {VolitionsService} from "../../../../../services/http-services/volitions.service";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {Placement} from "@popperjs/core";

@Component({
    selector: 'ogp-body-parts-selection',
    templateUrl: "body-parts-selection.component.html",
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        MatCheckbox,
        NgForOf,
        ObCheckboxDirective,
        ReactiveFormsModule,
        NgIf,
        MatIcon,
        OgpCardSelectionComponent,
        MatIconButton,
        ObButtonDirective,
        ObPopoverModule
    ]
})

export class BodyPartsSelectionComponent implements OnInit, AfterViewInit {
    @Input({required: true}) public selectedBodyParts!: BodyPartSelectionChoice[] | null;
    @Output() readonly updateBodyPartSelection: EventEmitter<BodyPartSelectionChoice[]> = new EventEmitter<BodyPartSelectionChoice[]>();
    @ViewChild('bodyPartSelectionSection', {static: false}) bodyPartSelectionSection!: ElementRef;
    public bodyPartSelectionChoices!: BodyPartSelectionChoice[];
    public bodyPartsSummaryCardBaseText!: string;
    public selectedBodyPartsText!: string;
    public donorCardOption: CardOption = {
        cardId: "body-parts-summary",
        cardHoverColor: "light-blue",
        cardBackgroundColor: "light-blue",
        optionKey: "DONOR",
        marginClass: "margin-t-2xl",
        tabbable: false
    }

    constructor(private readonly translate: TranslateService,
                private readonly volitionsService: VolitionsService,
                private readonly volitionService: VolitionService) {
    }

    public ngOnInit(): void {
        this.bodyPartsSummaryCardBaseText = this.translate.instant('volitions.myChoiceStep.donor.partialDonorSection.bodyPartsSummaryCardText');
        this.volitionsService.getBodyPartOptions().then((bodyPartDtos: BodyPartDto[]) => {
            this.bodyPartSelectionChoices = mapBodyPartDtosToBodyPartSelectionChoices(bodyPartDtos);
            if (this.selectedBodyParts !== null && this.bodyPartSelectionChoices != null) {
                this.bodyPartSelectionChoices = this.mergeSelectedBodyPartsIntoBodyPartSelectionChoices(this.bodyPartSelectionChoices, this.selectedBodyParts);
                this.updateBodyPartSummary();
            } else {
                this.selectedBodyPartsText = this.translate.instant('volitions.myChoiceStep.donor.partialDonorSection.noSelectionMade')
            }
        });
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            if (this.bodyPartSelectionSection) {
                this.bodyPartSelectionSection.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }, 200);
    }

    public updateBodyPartSummary(): void {
        const selectedBodyParts = this.bodyPartSelectionChoices?.filter(bodyPart => bodyPart.isChecked)
        this.selectedBodyPartsText = this.volitionService.getSelectedBodyPartsText(selectedBodyParts) ?? this.translate.instant('volitions.myChoiceStep.donor.partialDonorSection.noSelectionMade')
        this.updateBodyPartSelection.emit(selectedBodyParts);
    }

    private mergeSelectedBodyPartsIntoBodyPartSelectionChoices(
        bodyPartSelectionChoices: BodyPartSelectionChoice[],
        selectedBodyParts: BodyPartSelectionChoice[]
    ): BodyPartSelectionChoice[] {
        // Convert selectedBodyParts to a Set for quick lookup
        const selectedIds = new Set(selectedBodyParts.map(bp => bp.id));

        // Update the isChecked property based on whether the body part is selected
        return bodyPartSelectionChoices.map(bp => ({
            ...bp,
            isChecked: selectedIds.has(bp.id) // Mark as checked if it exists in selectedBodyParts
        }));
    }

    public getPlacement(bodyPartKey: string): Placement {
        const placements: Record<string, Placement> = {
            HEART: 'auto',
            LUNGS: 'auto',
            LIVER: 'auto',
            KIDNEYS: 'auto',
            SMALL_INTESTINE: 'auto',
            PANCREAS: 'bottom-end',
            STOMACH: 'auto',
            EYE_CORNEA: 'auto',
            EYE_SCLERA: 'auto',
            HEART_VALVES: 'auto',
            BLOOD_VESSELS: 'auto',
            HEART_BAG: 'auto',
        };
        return placements[bodyPartKey] || 'top-start';
    }
}