import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ObAlertComponent,
  ObButtonDirective,
  ObFormFieldDirective,
  ObNotificationService,
  ObSelectDirective
} from '@oblique/oblique';
import { MatCardActions, MatCardContent, MatCardTitle } from "@angular/material/card";
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { EmailValidatorDirective } from "../../validators/email-validator.directive";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatError, MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { PhoneNumberValidatorDirective } from "../../validators/phone-number-validator.directive";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { EmailVerificationService } from '../../../services/http-services/email-verification.service';
import { Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { EmailVerificationCodeValidatorDirective } from '../../validators/email-verification-code-validator.directive';
import { AuthService } from "../../../services/http-services/auth.service";
import { LoginService } from "@ogreg/ogreg-login";

@Component({
  selector: 'ogp-email-verification',
  standalone: true,
  imports: [CommonModule, ObAlertComponent, MatCardTitle, MatCardContent, TranslateModule, EmailValidatorDirective, MatButton, MatCardActions, MatCheckbox, MatError, MatFormField, MatHint, MatInput, MatLabel, MatOption, MatSelect, ObButtonDirective, ObFormFieldDirective, ObSelectDirective, PhoneNumberValidatorDirective, ReactiveFormsModule, MatIcon, EmailVerificationCodeValidatorDirective],
  templateUrl: './email-verification.component.html',
  styleUrl: './email-verification.component.scss'
})
export class EmailVerificationComponent {
  @Output() readonly emailReset: EventEmitter<boolean> = new EventEmitter<boolean>();
  public formGroup!: FormGroup;
  constructor(private readonly router: Router,
              private readonly formBuilder: FormBuilder,
              private readonly emailVerificationService: EmailVerificationService,
              private readonly notificationService: ObNotificationService,
              private readonly translationPipe: TranslatePipe,
              private readonly authService: AuthService,
              private readonly loginService: LoginService,
              private readonly dialog: MatDialog) {
    this.formGroup = this.formBuilder.group({
      code: [``, [Validators.required]],
    });
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.emailVerificationService.verifyEmail(this.formGroup?.value?.code).subscribe((response) => {
      if(response?.result) {
        if(response?.result == 'SUCCESS') {
          this.authService.exchangeToken().subscribe(token => {
            if(token.token) {
              this.loginService.setOgregToken(token.token);
            }
          })
        }
        this.showNotification(response?.result);
      }
    });
  }

  showNotification(result: string) {
    if (result === 'WRONG_CODE') {
      this.notificationService.error({
          title: this.translationPipe.transform('personRegister.contactDetails.emailVerification.errorNotification.title'),
          message: this.translationPipe.transform('personRegister.contactDetails.emailVerification.errorNotification.message'),
          timeout: 20000
        }
      );
    }
    if (result === 'SUCCESS') {
      this.notificationService.success({
          title: this.translationPipe.transform('personRegister.contactDetails.emailVerification.successNotification.title'),
          message: this.translationPipe.transform('personRegister.contactDetails.emailVerification.successNotification.message'),
          timeout: 20000
        }
      );
      this.navigateTo('home');
    }
  }


  navigateTo(routeName: string) {
    this.router.navigate([routeName]);
  }

  resendEmail(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.translationPipe.transform('personRegister.contactDetails.emailVerification.confirmationDialog.title'),
        content1: this.translationPipe.transform('personRegister.contactDetails.emailVerification.confirmationDialog.content1'),
        content2: this.translationPipe.transform('personRegister.contactDetails.emailVerification.confirmationDialog.content2')
      }
    }).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.emailVerificationService.resendEmail().subscribe(response => {
          if (response.success) {
            this.notificationService.success({
                message: this.translationPipe.transform('E-Mail-Adresse wurde zurückgesetzt'),
                timeout: 20000
              }
            );
          }
        })
      }
    });
  }

  reset(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.translationPipe.transform('personRegister.contactDetails.emailVerification.confirmationDialog.title'),
        content1: this.translationPipe.transform('personRegister.contactDetails.emailVerification.confirmationDialog.content1'),
        content2: this.translationPipe.transform('personRegister.contactDetails.emailVerification.confirmationDialog.content2')
      }
    }).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.emailVerificationService.reset().subscribe(response => {
          if (response.success) {
            this.emailReset.emit(true);
            this.notificationService.success({
                message: this.translationPipe.transform('E-Mail-Adresse wurde zurückgesetzt'),
                timeout: 20000
              }
            );
          }
        })
      }
    });
  }
}
