import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';

export function emailVerificationCodeValidator(): ValidatorFn {
  const EMAIL_CODE_REGEXP =
    /^\d{4}$/;
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const isValid = EMAIL_CODE_REGEXP.test(control.value);
      if (isValid) {
        return null;
      }
      return emailVerificationCodeValidatorResponse(false);
    } else  return null;
  };
}

function emailVerificationCodeValidatorResponse(valid: boolean) {
  return {
    emailVerificationCodeValidator: {
      valid
    }
  };
}

@Directive({
  selector: '[ogpEmailVerificationCodeValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailVerificationCodeValidatorDirective,
      multi: true
    }
  ],
  standalone: true
})
export class EmailVerificationCodeValidatorDirective implements Validator {
  public validate(control: AbstractControl): ValidationErrors | null {
    return emailVerificationCodeValidator()(control);
  }
}
