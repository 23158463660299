import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OgpStepperService {
    private stepSubject = new BehaviorSubject<number>(0); // Initial step is 0
    currentStep$ = this.stepSubject.asObservable();

    // Method to go to the next step
    public nextStep(): void {
        const nextStep = this.stepSubject.value + 1;
        this.stepSubject.next(nextStep);
    }

    // Method to go to the previous step
    public previousStep(): void {
        const previousStep = this.stepSubject.value - 1;
        this.stepSubject.next(previousStep);
    }

    public getCurrentMainStep(): number {
        return (this.stepSubject.value);
    }

    // Method to set a specific step (e.g., reset or jump)
    public setStep(step: number): void {
        this.stepSubject.next(step);
    }
}
