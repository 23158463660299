import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'ogp-breadcrumb',
    templateUrl: './ogp-breadcrumb.component.html',
    styleUrls: ['./ogp-breadcrumb.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        MatIcon,
        TranslateModule
    ]
})
export class BreadcrumbComponent implements OnInit{
    @Input({required: true}) public options!: BreadCrumbOptions;
    protected rootRouterLinkPath!: string | null;
    protected childRouterLinkPath!: string | null;

    public ngOnInit(): void {
        this.rootRouterLinkPath = this.options.pathRouterLink01 == null ? null : "/" + this.options.pathRouterLink01;
        this.childRouterLinkPath = this.options.pathRouterLink02 == null ? null : "/" + this.options.pathRouterLink02;
    }
}

export interface BreadCrumbOptions {
    pathTranslationKey01: string;
    pathRouterLink01?: string;
    pathTranslationKeyKey02: string;
    pathRouterLink02?: string;
}