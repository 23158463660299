<h1>Debug-Mode</h1>
<h2>Diese Person löschen</h2>
<ob-alert type="info" *ngIf="loginService.isLoggedIn() === null">Du musst eingeloggt sein um den derzeitigen Nutzer löschen zu können.</ob-alert>
<button mat-raised-button [disabled]="loginService.isLoggedIn() === null" (click)="deleteAllUserData()">Alle Daten von dem Nutzer löschen</button>
<button mat-raised-button [disabled]="loginService.isLoggedIn() === null" (click)="deleteUserDataPersonsRegister()">Alle Daten des Nutzers aus dem Personenregister löschen</button>
<button mat-raised-button [disabled]="loginService.isLoggedIn() === null" (click)="deleteUserDataVolitionDb()">Alle Daten des Nutzers aus der Volition-DB löschen</button>

<h2>Ganzen Datenbank-Inhalt löschen</h2>
<button mat-raised-button (click)="deleteAllData()">Alle Daten löschen</button>
<button mat-raised-button (click)="deleteEverythingPersonRegister()">Alle Daten aus dem Personenregister löschen</button>
<button mat-raised-button (click)="deleteEverythingVolitionDb()">Alle Daten aus der Volition-DB löschen</button>

<h2>Daten neu laden</h2>
<button mat-raised-button (click)="refreshData()">Refresh</button>

<h2>Alle Personen</h2>
<table mat-table [dataSource]="personDataSource">
  @for (column of displayedColumnsPerson; track column) {
    <ng-container [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef> {{column}} </th>
      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
    </ng-container>
  }

  <tr mat-header-row *matHeaderRowDef="displayedColumnsPerson"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsPerson;"></tr>
</table>

<h2> Alle EIAM-Mappings </h2>
<table mat-table [dataSource]="eiamMappingsDataSource">
  @for (column of displayedColumnsEiamMappings; track column) {
    <ng-container [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef> {{column}} </th>
      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
    </ng-container>
  }

  <tr mat-header-row *matHeaderRowDef="displayedColumnsEiamMappings"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsEiamMappings;"></tr>
</table>

<h2>Alle Mail-Verifications</h2>
<table mat-table [dataSource]="allVerifications">
  @for (column of displayedColumnsVerification; track column) {
    <ng-container [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef> {{column}} </th>
      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
    </ng-container>
  }

  <tr mat-header-row *matHeaderRowDef="displayedColumnsVerification"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsVerification;"></tr>
</table>

<h2>All Volitions</h2>
<table mat-table [dataSource]="allVolitions">
  @for (column of displayedColumnsVolitions; track column) {
    <ng-container [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef> {{column}} </th>
      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
    </ng-container>
  }

  <tr mat-header-row *matHeaderRowDef="displayedColumnsVolitions"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsVolitions;"></tr>
</table>
