import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  get ogPersonRegisterUrl() {
    return environment.personRegisterUrl;
  }

  get ogVolitionUrl() {
    return environment.volitionUrl;
  }

  get ogVolitionReadUrl() {
    return environment.volitionReadUrl
  }

  get ogVerificationUrl() {
    return environment.verificationUrl;
  }
}
