import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatLabel } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { PersonDto } from '@ogreg/shared';
import { ObExternalLinkDirective } from '@oblique/oblique';

@Component({
  selector: 'ogp-personal-data',
  standalone: true,
  imports: [CommonModule, MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle, MatLabel, TranslateModule, ObExternalLinkDirective],
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.scss'
})
export class PersonalDataComponent {
  @Input() eIamPerson!: PersonDto;
}
