import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { MailVerificationCodeDto, MailVerificationInformationDto } from "@ogreg/shared";

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationService {
  model = 'mail-verification';

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService
  ) {}

  verifyEmail(code: MailVerificationCodeDto): Observable<any> {
    return this.http.post<MailVerificationCodeDto>(
      `${this.env.ogVerificationUrl}${this.model}/verify-mail`, {
        code: code
      }
    );
  }

  getCurrentVerification(): Observable<MailVerificationInformationDto> {
    return this.http.get<MailVerificationInformationDto>(
      `${this.env.ogVerificationUrl}${this.model}/current-verification`
    );
  }

  resendEmail(): Observable<any> {
    return this.http.get<MailVerificationCodeDto>(
      `${this.env.ogVerificationUrl}${this.model}/resend-mail`
    );
  }

  reset(): Observable<any> {
    return this.http.get<MailVerificationCodeDto>(
      `${this.env.ogVerificationUrl}${this.model}/reset`
    );
  }
}
