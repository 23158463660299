import {Injectable} from '@angular/core';
import {VolitionDto} from "../generated/model/modelsShared";
import {Router} from "@angular/router";
import {PersonDto} from "@ogreg/shared";
import {BehaviorSubject, catchError, lastValueFrom, Observable, switchMap, tap, throwError} from "rxjs";
import {PersonsService} from "../services/http-services/persons.service";
import {BackgroundColor, HoverColor} from "../shared/components/ogp-card-selection/ogp-card-selection.component";
import {VolitionsService} from "../services/http-services/volitions.service";
import {TranslatePipe} from "@ngx-translate/core";
import {ObNotificationService} from "@oblique/oblique";
import {BodyPartDto} from "../generated/model/modelsQuery";

@Injectable({
    providedIn: 'root'
})
export class VolitionService {
    private donationDataSubject = new BehaviorSubject<OrganAndTissueDonationEntry | null>(null);

    constructor(private readonly router: Router,
                private personsService: PersonsService,
                private volitionsService: VolitionsService,
                private notificationService: ObNotificationService,
                private readonly translationPipe: TranslatePipe) {
    }


    // Expose the state as an observable
    public getDonationData(): Observable<OrganAndTissueDonationEntry | null> {
        return this.donationDataSubject.asObservable();
    }

    public getDonationDataValue(): OrganAndTissueDonationEntry {
        const donationData = this.donationDataSubject.getValue();
        if (!donationData) {
            throw new Error('Donation data is not initialized.');
        }
        return donationData
    }

    public async initDonationData(): Promise<void> {
        try {
            const [personDto, volitionDto, isVolitionCompletedDto] = await Promise.all([
                this.getPersonDto(),
                this.volitionsService.getVolitionDto(),
                this.volitionsService.checkIsVolitionCompleted(),
            ]);

            if (!personDto || !volitionDto || !isVolitionCompletedDto) {
                throw new Error('Incomplete data from API responses.');
            }

            // Update state
            const donationData: OrganAndTissueDonationEntry = {
                person: personDto,
                volition: this.mapVolitionDtoToVolitionData(volitionDto, isVolitionCompletedDto.completed || false),
            };

            this.donationDataSubject.next(donationData);
        } catch (error) {
            console.error('Error initializing donation data:', error);
        }
    }

    // Generic setValue function to update any property dynamically
    public setDonationVolitionData<K extends keyof VolitionData>(keyName: K, value: VolitionData[K]): void {
        const currentData = this.donationDataSubject.getValue();
        if (currentData && currentData.volition) {
            // Update the specific property
            currentData.volition[keyName] = value;

            // Emit the updated data
            this.donationDataSubject.next({...currentData});
        } else {
            console.error('Volition data is not initialized.');
        }
    }

    public navigateToPage(routerName: string) {
        this.navigateTo(routerName);
    }

    //TODO: move this function to the Router
    private navigateTo(routeName: string) {
        this.router.navigate([routeName]);
    }

    async getPersonDto(): Promise<PersonDto> {
        return await lastValueFrom(this.personsService.getPersonDto());
    }

    public saveVolitionDto(): Observable<VolitionDto> {
        const volition = this.donationDataSubject.getValue()?.volition;

        if (!volition) {
            return throwError(() => new Error('There is no volition set yet'));
        }

        const volitionDto = this.mapVolitionDataToVolitionDto(volition);

        return this.volitionsService.createVolition(volitionDto).pipe(
            switchMap(() => this.volitionsService.getVolitionDto()),
            tap((volitionDto: VolitionDto) => {
                if (volitionDto.id) {
                    this.notificationService.success({
                        title: this.translationPipe.transform('volitions.viewConfirmationStep.voltionSuccessfulAlert.title'),
                        message: this.translationPipe.transform('volitions.viewConfirmationStep.voltionSuccessfulAlert.text'),
                        timeout: 20000,
                    });
                } else {
                    throw new Error('Volition creation succeeded but ID is missing.');
                }
            }),
            catchError((error) => {
                console.error('Error saving volition:', error);
                return throwError(() => error);
            })
        );
    }

    public getSelectedBodyPartsText(selectedBodyParts?: BodyPartSelectionChoice[] | null): string | null {
        if (selectedBodyParts == null) {
            return null;
        }

        return selectedBodyParts
            .map(bodyPart => {
                return this.translationPipe.transform('volitions.myChoiceStep.donor.organs.' + bodyPart.key + '.title');
            })
            .join(', ');
    }

    // Map VolitionDto to VolitionData
    public mapVolitionDtoToVolitionData(dto: VolitionDto, isVolitionCompleted: boolean): VolitionData {
        return isVolitionCompleted
            ? {
                volitionId: dto.id,
                creationDate: dto.timeGiven,
                decision: dto.donorType,
                lastCompletedStep: "viewTemplateStep",
                lastCompletedMainStep: "STORAGE_STEP",
                selectedBodyParts: this.mapVolitionBodyPartIdToBodyPartSelectionChoice(dto.bodyPartIds ?? null),
                acceptedLegalText: dto.acceptedLegalText,
                isVolitionCompleted: true
            } as VolitionData
            : {
                creationDate: null,
                decision: DonorOptionEnum.DEFAULT,
                lastCompletedStep: null,
                lastCompletedMainStep: null,
                // TODO: Set the acceptedLegalText to false for Go-Live
                selectedBodyParts: null,
                acceptedLegalText: true,
                isVolitionCompleted: false
            } as VolitionData
    }

    // Map selected BodyPartIds to BodyPartSelectionChoice
    private mapVolitionBodyPartIdToBodyPartSelectionChoice(bodyPartIds: string[] | null): BodyPartSelectionChoice[] | null {
        if (bodyPartIds === null) {
            return null;
        }
        let selectedBodyParts: BodyPartSelectionChoice[] | null = null;
        this.volitionsService.getBodyPartOptions().then((bodyPartDtos: BodyPartDto[]) => {
            if (bodyPartDtos != null) {
                selectedBodyParts = bodyPartDtos
                    .filter(bodyPart => bodyPartIds.includes(bodyPart.id ?? 'UNDEFINED'))
                    .map(bodyPart => {
                        return {
                            id: bodyPart.id,
                            key: bodyPart.translationKey,
                            isChecked: true
                        } as BodyPartSelectionChoice
                    })
            } else {
                console.error("Could not load BodyPartOptions for mapping");
            }
        });
        return selectedBodyParts
    }

    // Map VolitionDto to VolitionData
    private mapVolitionDataToVolitionDto(volitionData: VolitionData): VolitionDto {
        return {
            acceptedLegalText: volitionData.acceptedLegalText,
            bodyPartIds: volitionData.selectedBodyParts?.map((selectedBodyPart: BodyPartSelectionChoice) => selectedBodyPart.id),
            donorType: volitionData.decision
        };
    }

}


export type DonorOption = keyof typeof DonorOptionEnum;
export type SubStep =
    "donorOptionStep"
    | "myChoiceStep"
    | "viewTemplateStep"
    | "viewConfirmationStep"
    | "viewFinalRegistryEntry"
export type MainStep = keyof typeof MainStepEnum;
export type BodyParts = keyof typeof BodyPartsEnum;


export enum DonorOptionEnum {
    NON_DONOR = "NON_DONOR",
    DONOR = "DONOR",
    TRUSTED_PERSON = "TRUSTED_PERSON",
    FULL_DONOR = "FULL_DONOR",
    PARTIAL_DONOR = "PARTIAL_DONOR",
    DEFAULT = "DEFAULT"
}

export enum MainStepEnum {
    DECISION_STEP,
    CONFIRMATION_STEP,
    STORAGE_STEP
}

export enum BodyPartsEnum {
    HEART = "HEART",
    LUNGS = "LUNGS",
    LIVER = "LIVER",
    KIDNEYS = "KIDNEYS",
    SMALL_INTESTINE = "SMALL_INTESTINE",
    PANCREAS = "PANCREAS",
    STOMACH = "STOMACH",
    EYE_CORNEA = "EYE_CORNEA",
    EYE_SCLERA = "EYE_SCLERA",
    HEART_VALVES = "HEART_VALVES",
    BLOOD_VESSELS = "BLOOD_VESSELS",
    HEART_BAG = "HEART_BAG",
}

export interface NavigationStepData {
    activeStep: MainStep,
    activeSubStep: SubStep,
    lastCompletedStep: SubStep | null
    action?: "created" | "deleted" | "next" | "back"
}

export interface OrganAndTissueDonationEntry {
    person: PersonDto | null,
    volition: VolitionData | null
}

export interface VolitionData {
    volitionId: string | null,
    creationDate: string | null,
    lastCompletedStep: SubStep | null,
    lastCompletedMainStep: MainStep | null,
    decision: DonorOption,
    selectedBodyParts: BodyPartSelectionChoice[] | null,
    acceptedLegalText?: boolean,
    isVolitionCompleted: boolean
}

export interface BodyPartSelectionChoice {
    id: string,
    key: string,
    isChecked: boolean
}

export function getDonorOptionHoverColor(donorOption: DonorOption): HoverColor {
    switch (donorOption) {
        case "DONOR":
        case "FULL_DONOR":
        case "PARTIAL_DONOR":
            return "light-blue";
        case "NON_DONOR":
            return "light-orange";
        case "TRUSTED_PERSON":
            return "light-green";
        default:
            return "none";
    }
}

export function getDonorOptionBackgroundColor(donorOption: DonorOption): BackgroundColor {
    switch (donorOption) {
        case "DONOR":
        case "FULL_DONOR":
        case "PARTIAL_DONOR":
            return "light-blue";
        case "NON_DONOR":
            return "light-orange";
        case "TRUSTED_PERSON":
            return "light-green";
        default:
            return "none";
    }
}

// Type Guard to validate if a string is a valid DonorOption
export function isDonorOption(value: string): value is DonorOption {
    return Object.values(DonorOptionEnum).includes(value as DonorOptionEnum);
}


export function mapBodyPartDtosToBodyPartSelectionChoices(bodyPartDtos: BodyPartDto[]): BodyPartSelectionChoice[] {
    return bodyPartDtos.map((bodyPartDto: BodyPartDto) => {
        return {
            id: bodyPartDto.id,
            key: bodyPartDto.translationKey,
            isChecked: false,
        } as BodyPartSelectionChoice
    })
}