import { Component } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'ogp-faq',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './faq.component.html'
})
export class FaqComponent {

}
