import {Injectable} from '@angular/core';
import {lastValueFrom, Observable} from 'rxjs';
import {EnvironmentService} from '../environment/environment.service';
import {HttpClient} from '@angular/common/http';
import {VolitionDto} from '../../generated/model/modelsShared';
import {BodyPartDto, VolitionCompletedDto} from "../../generated/model/modelsQuery";

@Injectable({
    providedIn: 'root'
})
export class VolitionsService {
    model = 'volitions';

    constructor(
        private readonly http: HttpClient,
        private readonly env: EnvironmentService
    ) {
    }

    //TODO: Make this private and use getVolitionDto for Public puroeses
    getVolition(): Observable<VolitionDto> {
        return this.http.get<VolitionDto>(`${this.env.ogVolitionReadUrl}${this.model}`);
    }

    createVolition(volitionDto: VolitionDto): Observable<any> {
        return this.http.post<any>(
            `${this.env.ogVolitionUrl}${this.model}`,
            {volitionDto: volitionDto}
        );
    }

    async getVolitionDto(): Promise<VolitionDto> {
        return await lastValueFrom(this.getVolition());
    }

    editVolition(volitionDto: {
        bodyPartIds: (string | undefined)[];
        donorType: string | undefined;
    }): Observable<void> {
        return this.http.put<void>(
            `${this.env.ogVolitionUrl}${this.model}`,
            {volitionDto: volitionDto}
        );
    }

    private getBodyPartsDto(): Observable<BodyPartDto[]> {
        return this.http.get<BodyPartDto[]>(`${this.env.ogVolitionReadUrl}body-parts`);
    }

    public async getBodyPartOptions(): Promise<BodyPartDto[]> {
        return await lastValueFrom(this.getBodyPartsDto());
    }

    //TODO: Make this private and use checkIsVolitionCompleted for Public puroeses
    isVolitionCompleted(): Observable<VolitionCompletedDto> {
        return this.http.get<VolitionCompletedDto>(
            `${this.env.ogVolitionReadUrl}${this.model}/completed`
        )
    }

    public async checkIsVolitionCompleted(): Promise<VolitionCompletedDto> {
        return await lastValueFrom(this.isVolitionCompleted());
    }
}
