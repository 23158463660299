<div class="ob-grid">
    <div class="ob-span-4"></div>
    <div class="ob-span-4">
        <br/>
        <br/>
        <br/>
        <div class="card-container">
            <mat-card>
                <mat-card-content>
                    <h2>Login</h2>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <p>
                            <input
                                    type="text"
                                    matInput
                                    [placeholder]="'login.username' | translate"
                                    formControlName="userName"
                            />
                        </p>
                        <p>
                            <input
                                    type="text"
                                    [placeholder]="'login.password' | translate"
                                    formControlName="password"
                            />
                        </p>
                        <br/>
                        <button type="submit" mat-button obButton="primary">Log In</button>
                        <button
                                type="button"
                                style="margin-left: 10px"
                                mat-button
                                (click)="onAuthenticateRandom()"
                                obButton="secondary"
                        >
                            Random
                        </button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="ob-span-4"></div>
</div>
