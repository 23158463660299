import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../services/http-services/auth.service';
import { Router } from '@angular/router';
import { LoginLibComponent, LoginService } from '@ogreg/ogreg-login';
import { tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ObButtonDirective } from '@oblique/oblique';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatCard, MatCardContent } from '@angular/material/card';
import { JwtDto, UserCredentialsDto } from "@ogreg/shared";

@Component({
    selector: 'ogp-login',
    templateUrl: './login.component.html',
    standalone: true,
    imports: [MatCard, MatCardContent, FormsModule, ReactiveFormsModule, MatInput, MatButton, ObButtonDirective, TranslateModule, LoginLibComponent]
})
export class LoginComponent {
    afterLoginNavigateTo = 'person-register';
    form: FormGroup = new FormGroup({
        name: new FormControl('')
    });

    constructor(
        private readonly authService: AuthService,
        private readonly loginService: LoginService,
        private readonly router: Router
    ) {
    }

    authenticate(credentials: UserCredentialsDto) {
        this.authService
            .authenticate(credentials)
            .pipe(
                tap((jwtDto: JwtDto) => {
                    this.handleSuccessfulAuthentication(jwtDto);
                })
            )
            .subscribe();
    }

    // delete this authenticateRandom() function once eIAM is implemented
    authenticateRandom(credentials: UserCredentialsDto) {
        this.authService
            .authenticateRandom(credentials)
            .pipe(
                tap((jwtDto: JwtDto) => {
                    this.handleSuccessfulAuthentication(jwtDto);
                })
            )
            .subscribe();
    }

    handleSuccessfulAuthentication(jwtDto: JwtDto) {
        if (jwtDto.token) {
            this.loginService.logIn(jwtDto.token);
            this.authService.exchangeToken().subscribe(token => {
                if(token.token) {
                    this.loginService.setOgregToken(token.token)
                    void this.router.navigate([this.afterLoginNavigateTo]);
                }
            })
        }
    }
}
