import { Component, ViewEncapsulation } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import { ObAlertComponent, ObNotificationService } from "@oblique/oblique";
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable, MatTableDataSource
} from "@angular/material/table";
import { EiamIdPersonIdMappingDto, PersonDto } from "@ogreg/shared";
import { MatButton } from "@angular/material/button";
import {
  VolitionDto
} from "../generated/model/modelsShared";
import { LoginService } from "@ogreg/ogreg-login";
import { NgIf } from "@angular/common";
import { DebugMailVerificationInformationDto } from "../generated/model/mail-verification-models";
import { tap } from "rxjs";

@Component({
  selector: 'ogp-debug',
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatRow,
    MatButton,
    MatHeaderRowDef,
    MatRowDef,
    ObAlertComponent,
    NgIf
  ],
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DebugComponent {

  verificationDataSource = new MatTableDataSource<DebugMailVerificationInformationDto>();
  volitionsDataSource = new MatTableDataSource<VolitionDto>();
  eiamMappingsDataSource = new MatTableDataSource<EiamIdPersonIdMappingDto>();

  allVerifications: DebugMailVerificationInformationDto[] = [];
  allVolitions: VolitionDto[] = [];
  allEiamMappings: EiamIdPersonIdMappingDto[] = [];

  displayedColumnsVerification = ['timeCreated', 'forPersonId', 'forEiamId', 'expirationTimestamp', 'code'];
  displayedColumnsVolitions = ['id', 'donorType', 'bodyPartIds', 'acceptedLegalText', 'timeGiven'];
  displayedColumnsVolitionAccess = ['id', 'volitionId', 'accessedBy', 'accessedAt', 'accessedByType', 'toBeDeletedAt'];
  displayedColumnsVolitionChange = ['volitionId', 'changedAt', 'toBeDeletedAt'];
  displayedColumnsEiamMappings = ['eiamId', 'personId'];

  constructor(private http: HttpClient, public loginService: LoginService, private notificationService: ObNotificationService) {
    this.refreshData();
  }
  deleteUserDataPersonsRegister() {
    this.http.post<{success?: boolean}>(environment.personRegisterUrl + "debug/deleteme", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten des Nutzers aus dem Personenregister gelöscht")
        this.deleteUserDataMailVerification().subscribe(res => {
          this.refreshData();
        })
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    })
  }

  deleteUserDataMailVerification() {
    return this.http.get(environment.verificationUrl + "debug/delete-me").pipe(tap(res => {
      this.notificationService.success("Alle Daten des Nutzers aus der Mail-Verifikation gelöscht")
    }));
  }

  deleteUserDataVolitionDb() {
    this.http.post<{success?: boolean}>(environment.volitionUrl + "debug/deleteme", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten des Nutzers aus der Volition-Db gelöscht")
        this.refreshData();
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    })
  }

  deleteEverythingPersonRegister() {
    this.http.post<{success?: boolean}>(environment.personRegisterUrl + "debug/delete-everything", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten aus dem Personenregister gelöscht")
        this.deleteEverythingMailVerification().subscribe(res => {
          this.refreshData();
        })
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    });
  }

  deleteEverythingMailVerification() {
    return this.http.get<{success?: boolean}>(environment.verificationUrl + "debug/delete-everything").pipe(tap(res => {
      this.notificationService.success("Alle Daten aus der Mail-Verifikation gelöscht");
    }))
  }

  deleteEverythingVolitionDb() {
    this.http.post<{success?: boolean}>(environment.volitionUrl + "debug/delete-everything", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten aus der Volition-Db gelöscht")
        this.refreshData();
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    });
  }

  deleteAllData() {
    this.deleteEverythingPersonRegister();
    this.deleteEverythingVolitionDb();
  }

  deleteAllUserData() {
    this.deleteUserDataPersonsRegister();
    this.deleteUserDataVolitionDb();
  }


  fetchAllMailVerifications() {
    this.http.get<DebugMailVerificationInformationDto[]>(environment.verificationUrl + "debug/all-mail-verifications").subscribe((res) => {
      this.allVerifications = res;
      this.verificationDataSource.data = this.allVerifications;
    })
  }

  fetchAllVolitions() {
    this.http.get<VolitionDto[]>(environment.volitionReadUrl + "debug/all-volitions").subscribe((res) => {
      this.allVolitions = res;
      this.volitionsDataSource.data = this.allVolitions;
    })
  }

  fetchAllEiamMapping() {
    this.http.get<EiamIdPersonIdMappingDto[]>(environment.personRegisterUrl + "debug/all-eiam-mappings").subscribe(res => {
      this.allEiamMappings = res;
      this.eiamMappingsDataSource.data = this.allEiamMappings
    })
  }


  refreshData() {
    this.fetchAllMailVerifications();
    this.fetchAllVolitions();
    this.fetchAllEiamMapping()
  }
}
