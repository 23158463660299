<mat-card [ngClass]="[hoverClass, marginClass, backgroundColor, cursorPointer]"
          (click)="onCardClick()"
          (keydown)="onCardEnterKeyPressed($event)"
          tabindex="{{ tabindex }}"
          [attr.data-cy]="cardId">
    <mat-card-header>
        <div class="header-content">
            <div class="flex-box-80">
                <mat-card-title *ngIf="cardTitleKey !== null">
                    <h3>{{ cardTitleKey | translate }}</h3>
                </mat-card-title>
                <mat-card-subtitle *ngIf="cardInformationKey !== null">
                    <span [innerHTML]="cardInformationWithParameter ?? cardInformationKey | translate"></span>
                </mat-card-subtitle>
            </div>
            <div *ngIf="iconPath"
                 class="avatar-content">
                <div mat-card-avatar>
                    <img [src]="iconPath" [alt]="headerIconAlt! | translate">
                </div>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <span *ngIf="dateLabel !== null">{{ dateLabel | translate }} <b>{{ date | date: 'dd.MM.yyyy' }}</b></span>
        <p *ngIf="cardContentTextKey !== null"> {{ cardContentTextKey | translate }}</p>
    </mat-card-content>
    <mat-card-content>
        <ng-content>
        </ng-content>
    </mat-card-content>
</mat-card>