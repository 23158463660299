import { PersonsService } from '../services/http-services/persons.service';
import { map } from 'rxjs';
import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { PersonDto } from "@ogreg/shared";

export const isRegisteredGuard: CanActivateFn = () => {
    // Step 1: Get the "ogregToken" from sessionStorage
    const ogregToken = window.sessionStorage.getItem('ogregToken');

    // Step 2: If no token is available, deny access
    if (ogregToken === null) {
        return false;
    }

    // Step 3: Decode the JWT token to get the payload
    const tokenParts = ogregToken.split('.');
    if (tokenParts.length !== 3) {
        return false; // If token is invalid, deny access
    }

    try {
        // Decode the payload part of the JWT token
        const payloadBase64Decoded = atob(tokenParts[1].replace('=',''));

        // Step 4: Check the "hasCompletedUserData" attribute
        const hasCompletedUserData:boolean = JSON.parse(payloadBase64Decoded)?.['ogreg-person']?.['hasCompletedUserData'] ?? false;

        // Step 5: Return true if hasCompletedUserData is true, otherwise false
        return hasCompletedUserData;
    } catch (error) {
        // If there's an error during parsing, deny access
        return false;
    }
};
