<mat-card class="user-guidance">
  <mat-card-header>
  <mat-card-title>
    <h3 data-cy="user-guidance-title">{{ "personRegister.userGuidance.title" | translate }}</h3>
  </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "personRegister.userGuidance.questions.1" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <p>Lorem ipsum dolor sit amet consectetur. Sagittis cursus nunc cursus nunc. Enim at interdum porta mollis. Id ac nibh egestas consequat duis leo.</p>
        <p>Ut ultricies enim vitae orci purus quis sem. Ridiculus scelerisque dui in in. Dolor velit commodo tincidunt vestibulum ullamcorper libero facilisi.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "personRegister.userGuidance.questions.2" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <p>Ihre Personendaten sind <b>nicht änderbar</b>, da sie direkt vom Bund übernommen werden. Dies stellt sicher, dass Ihr Spendewille korrekt zugeordnet wird.</p>
        <p>Sollten Ihre Personendaten nicht stimmen, wenden Sie sich an folgende Stelle: <a href="https://www.bit.admin.ch/bit/de/home.html" icon="right">ichbineineurl.ch</a>.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "personRegister.userGuidance.questions.3" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <p>Lorem ipsum dolor sit amet consectetur. Sagittis cursus nunc cursus nunc. Enim at interdum porta mollis. Id ac nibh egestas consequat duis leo.</p>
        <p>Ut ultricies enim vitae orci purus quis sem. Ridiculus scelerisque dui in in. Dolor velit commodo tincidunt vestibulum ullamcorper libero facilisi.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "personRegister.userGuidance.questions.4" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <p>Lorem ipsum dolor sit amet consectetur. Sagittis cursus nunc cursus nunc. Enim at interdum porta mollis. Id ac nibh egestas consequat duis leo.</p>
        <p>Ut ultricies enim vitae orci purus quis sem. Ridiculus scelerisque dui in in. Dolor velit commodo tincidunt vestibulum ullamcorper libero facilisi.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "personRegister.userGuidance.questions.5" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <p>Lorem ipsum dolor sit amet consectetur. Sagittis cursus nunc cursus nunc. Enim at interdum porta mollis. Id ac nibh egestas consequat duis leo.</p>
        <p>Ut ultricies enim vitae orci purus quis sem. Ridiculus scelerisque dui in in. Dolor velit commodo tincidunt vestibulum ullamcorper libero facilisi.</p>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>


