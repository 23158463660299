import {AppComponent} from './app/app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {provideAnimations} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app/app-routing.module';
import {BrowserModule, bootstrapApplication} from '@angular/platform-browser';
import {AngularMaterialModule} from './app/angular-material.module';
import {TranslatePipe, TranslateModule} from '@ngx-translate/core';
import {TokenInterceptor} from './app/auth/token.interceptor';
import {environment} from './environments/environment';
import {
    OB_BANNER,
    ObMasterLayoutModule,
    ObColumnLayoutModule,
    ObButtonModule,
    ObExternalLinkModule,
    ObIconModule,
    multiTranslateLoader
} from '@oblique/oblique';
import {HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient} from '@angular/common/http';
import {LOCALE_ID, isDevMode, importProvidersFrom} from '@angular/core';
import {provideOAuthClient} from "angular-oauth2-oidc";
import {registerLocaleData} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import {oauthClientConfig} from "./config/oauth-client-config";

registerLocaleData(localeDE);
registerLocaleData(localeEN);

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(ObMasterLayoutModule,
            ObColumnLayoutModule,
            ObButtonModule,
            ObExternalLinkModule,
            AngularMaterialModule,
            BrowserModule,
            AppRoutingModule,
            ObIconModule.forRoot(),
            TranslateModule.forRoot(multiTranslateLoader()),
            MatInputModule,
            FormsModule,
            ReactiveFormsModule,
            MatCheckboxModule,
            ServiceWorkerModule.register('ngsw-worker.js',
                {
                    enabled: !isDevMode(),
                    // Register the ServiceWorker as soon as the application is stable
                    // or after 30 seconds (whichever comes first).
                    registrationStrategy: 'registerWhenStable:30000'
                }
            )
        ), {
            provide: LOCALE_ID,
            useValue: 'de-CH'
        }, {
            provide: OB_BANNER,
            useValue: environment.banner
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        TranslatePipe,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideOAuthClient(oauthClientConfig)
    ]
}).catch(err => console.error(err));
