import {Component, EventEmitter, inject, Input, Output} from "@angular/core";
import {OgpCardContentComponent} from "../../../shared/components/ogp-card-content/ogp-card-content.component";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {
    DonorStepComponent,
    DonorType,
} from "./donor/donor-step.component";
import {MatButton} from "@angular/material/button";
import {ObButtonDirective} from "@oblique/oblique";
import {Router} from "@angular/router";
import {DonorOption, NavigationStepData} from "../../volition.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent,
} from "../../../shared/components/ogp-dialogs/alert-dialog/alert-dialog.component";

@Component({
    selector: "ogp-my-choice-step",
    templateUrl: "my-choice-step.component.html",
    standalone: true,
    imports: [
        OgpCardContentComponent,
        TranslateModule,
        NgSwitch,
        DonorStepComponent,
        NgSwitchCase,
        MatButton,
        ObButtonDirective,
        NgIf
    ]
})

export class MyChoiceStepComponent {
    @Input({required: true}) public myActiveChoice!: DonorOption;
    @Output() activeStepChanged: EventEmitter<NavigationStepData> = new EventEmitter<NavigationStepData>();
    protected showStepNavigationButtons = false;
    readonly dialog = inject(MatDialog);

    constructor(private readonly router: Router) {}

    public donorTypeSelected(donorType:DonorType): void {
        if(donorType === "FULL_DONOR" || donorType === "PARTIAL_DONOR") {
            this.showStepNavigationButtons = true;
        }
    }

    public navigateToChangeDecisionStep = () => {
        this.activeStepChanged.emit({
            activeStep: "DECISION_STEP",
            activeSubStep:"donorOptionStep",
            lastCompletedStep: null,
            data: null
        })
    }

    public navigateToNextStep = () => {
        this.activeStepChanged.emit({
            activeStep: "CONFIRMATION_STEP",
            activeSubStep: "viewTemplateStep",
            lastCompletedStep: "myChoiceStep",
            data: null
        })
    }

    public cancelVolitionProcess = () => {
        this.openAlertDialog().afterClosed().subscribe((canceled:string) => {
            if(canceled === "true"){
                this.navigateTo('home');
            }
        })
    }

    public openAlertDialog() {
        return this.dialog.open(AlertDialogComponent,  {
            width: '630px',
            height: '340px',
            data: {
                titleKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.title",
                textKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.text",
                confirmButtonKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.confirmButton",
                continueButtonKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.continueButton"
            }
        });
    }

    private navigateTo(routeName: string) {
        this.router.navigate([routeName]);
    }
}