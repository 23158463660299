import {Component, OnInit} from '@angular/core';
import {VolitionDto} from '../generated/model/modelsShared';
import {VolitionsService} from '../services/http-services/volitions.service';
import {Observable, switchMap, tap} from 'rxjs';
import {ObBreadcrumbModule, ObNotificationService} from '@oblique/oblique';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {NgIf} from '@angular/common';
import {BreadcrumbComponent, BreadCrumbOptions} from "../shared/components/ogp-breadcrumb/ogp-breadcrumb.component";
import {BodyPartDto} from "../generated/model/modelsQuery";
import {OgpStepperComponent} from "../shared/components/ogp-stepper/ogp-stepper.component";
import {OgpStepperService} from "../shared/components/ogp-stepper/ogp-stepper.service";
import {HelpComponent} from "../help/help.component";
import {FaqItem, OgpFaqSectionComponent} from "../shared/components/ogp-faq-section/ogp-faq-section.component";
import {DonorOptionStepComponent} from "./steps/donor-option-step/donor-option-step.component";
import {MyChoiceStepComponent} from "./steps/my-choice-step/my-choice-step.component";
import {MainStepEnum, NavigationStepData, SubStep, VolitionService} from "./volition.service";

@Component({
    selector: 'ogp-volition',
    templateUrl: './volition.component.html',
    styleUrls: ['./volition.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        ObBreadcrumbModule,
        BreadcrumbComponent,
        OgpStepperComponent,
        HelpComponent,
        OgpFaqSectionComponent,
        NgIf,
        DonorOptionStepComponent,
        MyChoiceStepComponent
    ]
})
export class VolitionComponent implements OnInit{
    protected breadcrumbOptions!: BreadCrumbOptions;
    protected volition$!: Observable<VolitionDto>;
    protected bodyParts$!: Observable<BodyPartDto[]>;
    protected stepData!: NavigationStepData;
    public stepperNames: string[] = ['Entscheid', 'Bestätigung', 'Ablage'];
    protected activeSubStep: SubStep = "donorOptionStep";
    protected currentStep = 0;
    protected faqItems!: FaqItem[];

    constructor(
        private readonly volitionsService: VolitionsService,
        private readonly volitionService: VolitionService,
        private readonly notificationService: ObNotificationService,
        private readonly translationPipe: TranslatePipe,
        private stepperService: OgpStepperService
    ) {
        // Subscribe to the current step to keep track in the parent component
        this.stepperService.currentStep$.subscribe(step => {
            this.currentStep = step;
        });
    }

    public ngOnInit(): void {
        this.volition$ = this.getVolition();
        this.bodyParts$ = this.volitionsService.getBodyParts();
        this.breadcrumbOptions = {
            pathTranslationKey01 : "homePage.title",
            pathTranslationKeyKey02: "volitions.title",
            pathRouterLink01: "home",
        }
        this.faqItems = ["q1","q2","q3","q4","q5","q6","q7",].map(key => {
            return {
                questionKey: `volitions.faq.${key}.question`,
                answerKey: `volitions.faq.${key}.answer`
            }
        });
        this.activeSubStep = "donorOptionStep";
        this.stepData = {
            activeStep: "DECISION_STEP",
            activeSubStep: "donorOptionStep",
            lastCompletedStep: null,
            data: null,
        }
    }

    handleActiveStepChanged(navStepData: NavigationStepData): void {
        this.stepData = navStepData;
        if(this.stepperService.getCurrentMainStep() < MainStepEnum[this.stepData.activeStep])
        {
            this.volitionService.getNextMainVolitionStep();
        }
    }

    private getVolition(): Observable<VolitionDto> {
        return this.volitionsService.getVolition();
    }

    protected onVolitionChange(volitionDto: VolitionDto): void {
        if (!volitionDto.id) {
            this.volition$ = this.volitionsService.createVolition(volitionDto).pipe(
                switchMap(() => this.getVolition()),
                tap((volitionDto: VolitionDto) => {
                    if (volitionDto?.id) {
                        this.notificationService.success({
                                message: this.translationPipe.transform('volitions.volitionCreatedNotification'),
                                timeout: 20000
                            }
                        );
                    }
                })
            );
        } else {
            // @ts-expect-error refactor it
            this.volition$ = this.volitionsService.editVolition(volitionDto).pipe(
                switchMap(() => this.getVolition()),
                tap((volitionDto: VolitionDto) => {
                    if (volitionDto?.id) {
                        this.notificationService.success({
                                message: this.translationPipe.transform('volitions.volitionEditedNotification'),
                                timeout: 20000
                            }
                        );
                    }
                })
            );
        }
    }
}

