import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { ObExternalLinkModule } from '@oblique/oblique';

@Component({
  selector: 'ogp-user-guidance-person-register',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    MatCard,
    MatCardTitle,
    MatCardContent,
    MatCardHeader,
    ObExternalLinkModule
  ],
  templateUrl: './user-guidance-person-register.component.html',
  styleUrl: './user-guidance-person-register.component.scss'
})
export class UserGuidancePersonRegisterComponent {
}
