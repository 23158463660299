import {Component, EventEmitter, inject, OnInit, Output} from "@angular/core";
import {OgpCardContentComponent} from "../../../shared/components/ogp-card-content/ogp-card-content.component";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf} from "@angular/common";
import {DonorStepComponent,} from "./donor/donor-step.component";
import {MatButton} from "@angular/material/button";
import {ObButtonDirective, ObNotificationService} from "@oblique/oblique";
import {
    BodyPartSelectionChoice,
    DonorOption,
    DonorOptionEnum,
    NavigationStepData,
    VolitionService
} from "../../volition.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent,} from "../../../shared/components/ogp-dialogs/alert-dialog/alert-dialog.component";

@Component({
    selector: "ogp-my-choice-step",
    templateUrl: "my-choice-step.component.html",
    standalone: true,
    imports: [
        OgpCardContentComponent,
        TranslateModule,
        DonorStepComponent,
        MatButton,
        ObButtonDirective,
        NgIf
    ]
})

export class MyChoiceStepComponent implements OnInit {
    @Output() activeStepChanged: EventEmitter<NavigationStepData> = new EventEmitter<NavigationStepData>();
    readonly dialog = inject(MatDialog);
    protected showStepNavigationButtons = false;
    protected selectedDonorOption!: SelectedDonorOption | null;
    public myActiveChoice?: DonorOption | null;
    public selectedBodyParts: BodyPartSelectionChoice[] | null = null;

    constructor(private readonly volitionService: VolitionService,
                private notificationService: ObNotificationService) {
    }

    public ngOnInit(): void {
        const donationData = this.volitionService.getDonationDataValue(); // Custom helper method
        this.myActiveChoice = donationData?.volition?.decision ?? null;
        this.selectedDonorOption = this.getSelectedDonorOption(this.myActiveChoice)
        if (this.selectedDonorOption != null) {
            this.showStepNavigationButtons = true;
            this.selectedBodyParts = donationData.volition?.selectedBodyParts ?? null;
        }
    }

    private getSelectedDonorOption(donorOption: DonorOption | null): SelectedDonorOption | null {
        if (donorOption == null) {
            return null;
        }
        return [DonorOptionEnum.FULL_DONOR, DonorOptionEnum.PARTIAL_DONOR].includes(donorOption as SelectedDonorOption)
            ? donorOption as SelectedDonorOption
            : null
    }

    public donorTypeSelected(donorType: SelectedDonorOption): void {
        this.selectedDonorOption = donorType;
        if (this.selectedDonorOption != null) {
            this.showStepNavigationButtons = true;
        }
    }

    public bodyPartsSelectionChanged(selectedBodyParts: BodyPartSelectionChoice[]): void {
        this.selectedBodyParts = selectedBodyParts;
    }

    public navigateToChangeDecisionStep = () => {
        this.volitionService.setDonationVolitionData("lastCompletedMainStep", null);
        this.volitionService.setDonationVolitionData("lastCompletedStep", null);
        this.volitionService.setDonationVolitionData("decision", DonorOptionEnum.DEFAULT);
        this.activeStepChanged.emit({
            activeStep: "DECISION_STEP",
            activeSubStep: "donorOptionStep",
            lastCompletedStep: null
        })
    }

    public navigateToNextStep = () => {
        if (this.selectedDonorOption === DonorOptionEnum.PARTIAL_DONOR && (this.selectedBodyParts === null || this.selectedBodyParts.length === 0)) {
            this.notificationService.warning(
                {
                    title: "volitions.myChoiceStep.donor.partialDonorSection.noSelectionMadeWarningMessage.title",
                    message: "volitions.myChoiceStep.donor.partialDonorSection.noSelectionMadeWarningMessage.message",
                    timeout: 5000
                }
            );
            return;
        }
        this.volitionService.setDonationVolitionData("lastCompletedMainStep", "DECISION_STEP");
        this.volitionService.setDonationVolitionData("lastCompletedStep", "myChoiceStep");
        this.volitionService.setDonationVolitionData("decision", this.selectedDonorOption as DonorOption);
        this.volitionService.setDonationVolitionData("selectedBodyParts", this.selectedBodyParts);
        this.activeStepChanged.emit({
            activeStep: "CONFIRMATION_STEP",
            activeSubStep: "viewTemplateStep",
            lastCompletedStep: "myChoiceStep"
        })
    }

    public cancelVolitionProcess = () => {
        this.openAlertDialog().afterClosed().subscribe((canceled: string) => {
            if (canceled === "true") {
                this.volitionService.navigateToPage('home');
            }
        })
    }

    public openAlertDialog() {
        return this.dialog.open(AlertDialogComponent, {
            width: '630px',
            height: '340px',
            data: {
                titleKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.title",
                textKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.text",
                confirmButtonKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.confirmButton",
                continueButtonKey: "volitions.myChoiceStep.donor.preventMyChoiceStepFromClose.continueButton"
            }
        });
    }
}

export type SelectedDonorOption = DonorOptionEnum.FULL_DONOR | DonorOptionEnum.PARTIAL_DONOR;