import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ogp-help',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIcon],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss'
})
export class HelpComponent {}
