
<h1> Debug Login </h1>

<input matInput [(ngModel)]="id" data-cy="id" >
<input matInput [(ngModel)]="firstName" data-cy="firstName" >
<input matInput [(ngModel)]="lastName" data-cy="lastName">
<input matInput [(ngModel)]="birthDate" data-cy="birthDate">
<input matInput [(ngModel)]="nationality" data-cy="nationality">
<input matInput [(ngModel)]="placeOfBirth" data-cy="placeOfBirth">
<input matInput [(ngModel)]="homeTown" data-cy="homeTown">
<input matInput [(ngModel)]="socialSecurityNumber" data-cy="socialSecurityNumber">
<input matInput [(ngModel)]="gender" data-cy="gender">


<button (click)="login()" data-cy="login-button"> Login </button>