import {Component, Input} from "@angular/core";
import {
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {ObExternalLinkDirective} from "@oblique/oblique";
import {TranslateModule} from "@ngx-translate/core";
import {NgForOf} from "@angular/common";

@Component({
    selector: 'ogp-faq-section',
    templateUrl: './ogp-faq-section.component.html',
    styleUrl: './ogp-faq-section.component.scss',
    standalone: true,
    imports: [
        MatAccordion,
        MatCard,
        MatCardContent,
        MatCardHeader,
        MatCardTitle,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        ObExternalLinkDirective,
        TranslateModule,
        NgForOf
    ]
})
export class OgpFaqSectionComponent {
    @Input() public faqSectionTitle!: string;
    @Input() public faqItems?: FaqItem[];
}

export interface FaqItem {
    questionKey: string,
    answerKey: string
}