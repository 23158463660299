<ob-master-layout [navigation]="navigation">
  <ng-container obHeaderTitle>
    <span class="hidden-lg">{{ "title" | translate }}</span>
    <span class="shown-lg">
      <mat-card-title>
          OGreg
      </mat-card-title>
      <mat-card-subtitle>
          {{ "title" | translate }}
      </mat-card-subtitle>
    </span>
  </ng-container>
  <ng-template *ngIf="isLoggedIn()" #obHeaderControl>
    <button mat-button obButton="secondary" type="button" (click)="logOut()">
      <mat-icon svgIcon="login" />
      {{ "logOut" | translate }}
    </button>
  </ng-template>
  <ng-container obFooterInfo>
    <ng-template #obFooterLink>
      <a [href]="'footer.links.bag.url' | translate">
        {{ "footer.links.bag.text" | translate }}
      </a>
    </ng-template>
    <ng-template #obFooterLink>
      <a [href]="'footer.links.legalBasis.url' | translate">
        {{ "footer.links.legalBasis.text" | translate }}
      </a>
    </ng-template>
    <ng-template #obFooterLink>
      <a [href]="'footer.links.legalNotice.url' | translate">
        {{ "footer.links.legalNotice.text" | translate }}
      </a>
    </ng-template>
    <ng-template #obFooterLink>
      <a [href]="'footer.links.accessibility.url' | translate">
        {{ "footer.links.accessibility.text" | translate }}
      </a>
    </ng-template>
    <ng-container obFooterInfo>
      <p>&copy; {{ "footer.obFooterInfo" | translate }}</p>
    </ng-container>
  </ng-container>
</ob-master-layout>
