<mat-card [ngClass]="cardClass !== null ? cardClass : ''">
    <mat-card-header>
        <div class="header-content block">
            <div class="text-content">
                <mat-card-title [ngClass]="'full-width'" *ngIf="cardTitle !== null">
                    <h2 class="margin-0">{{ cardTitle | translate }}</h2>
                </mat-card-title>
                <mat-card-subtitle *ngIf="cardInformation !== null">
                    {{ cardInformation | translate }}
                </mat-card-subtitle>
            </div>
            <div *ngIf="buttonLabel !== null" class="button-container">
                <button (click)="onButtonClick()"
                        [disabled]="disabled"
                        [matTooltip]="buttonToolTip ?? (buttonLabel | translate)"
                        [attr.aria-label]="buttonLabel | translate"
                        mat-button
                        obButton="secondary"
                        type="button">
                    <mat-icon *ngIf="iconName !== null && iconAtStart" class="ob-icon" [svgIcon]="iconName" />
                    <span [ngClass]="{'hidden-lg': iconName !== null}">{{ buttonLabel | translate }}</span>
                    <mat-icon *ngIf="iconName !== null && !iconAtStart" class="ob-icon" [svgIcon]="iconName" />
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>
    <mat-card-actions *ngIf="hasActionButton">
        <ng-content select="[action-button-content]"></ng-content>
    </mat-card-actions>
</mat-card>