import {Component, EventEmitter, inject, Input, OnInit, Output} from "@angular/core";
import {
    CardOption,
    OgpCardSelectionComponent
} from "../../../../shared/components/ogp-card-selection/ogp-card-selection.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {NgForOf, NgIf} from "@angular/common";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {ObButtonDirective} from "@oblique/oblique";
import {FormsModule} from "@angular/forms";
import {SelectedDonorOption} from "../my-choice-step.component";
import {BodyPartsSelectionComponent,} from "./body-parts-selection/body-parts-selection.component";
import {BodyPartSelectionChoice, DonorOptionEnum} from "../../../volition.service";
import {MatDivider} from "@angular/material/divider";

@Component({
    selector: "ogp-donor-step",
    templateUrl: "donor-step.component.html",
    styleUrls: ['./donor-step.component.scss'],
    standalone: true,
    imports: [
        OgpCardSelectionComponent,
        TranslateModule,
        MatRadioGroup,
        MatRadioButton,
        NgForOf,
        FormsModule,
        BodyPartsSelectionComponent,
        NgIf,
        MatDivider
    ]
})

export class DonorStepComponent implements OnInit {
    @Input({required: true}) public selectedDonorType!: SelectedDonorOption | null;
    @Input({required: true}) public selectedBodyParts!: BodyPartSelectionChoice[] | null;
    @Output() public donorSelectionChanged: EventEmitter<SelectedDonorOption> = new EventEmitter<SelectedDonorOption>();
    @Output() public bodyPartsSelectionChanged: EventEmitter<BodyPartSelectionChoice[]> = new EventEmitter<BodyPartSelectionChoice[]>();
    readonly dialog = inject(MatDialog);
    protected donorCardOption!: CardOption;
    protected donorRadioItems: DonorRadioItem[] = [
        {
            label: "volitions.myChoiceStep.donor.FULL_DONOR",
            value: "FULL_DONOR"
        }, {
            label: "volitions.myChoiceStep.donor.PARTIAL_DONOR",
            value: "PARTIAL_DONOR"
        }
    ];

    public async ngOnInit(): Promise<void> {
        this.donorCardOption = {
            cardId: "donor",
            cardTitleKey: `volitions.decisionStep.DONOR.title`,
            cardInformationKey: `volitions.decisionStep.DONOR.information`,
            headerIconNameKey: `volitions.decisionStep.DONOR.headerIconName`,
            cardHoverColor: "light-blue",
            cardBackgroundColor: "light-blue",
            headerIconAlt: `volitions.decisionStep.DONOR.headerIconAlt`,
            optionKey: "DONOR",
            marginClass: "margin-0",
            tabbable: false
        }
    }

    protected onSelectionChange(event: MatRadioChange): void {
        this.selectedDonorType = event.value;
        if (this.selectedDonorType != null) {
            this.donorSelectionChanged.emit(this.selectedDonorType);
        }
    }

    public openDialog() {
        this.dialog.open(OrgansAndTissueOverviewDialogComponent, {
            width: '910px',
        });
    }

    public onDonorInformationEnterKeyPressed(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.openDialog();
        }
    }

    protected readonly DonorOptionEnum = DonorOptionEnum;

    public updateBodyPartSelection(selectedBodyParts: BodyPartSelectionChoice[]) {
        this.bodyPartsSelectionChanged.emit(selectedBodyParts)
    }
}

export type DonorType = "FULL_DONOR" | "PARTIAL_DONOR"

export interface DonorRadioItem {
    label: string,
    value: DonorType
}

@Component({
    selector: 'ogp-organs-and-tissue-overview-dialog',
    templateUrl: 'organs-and-tissue-overview-dialog.html',
    styleUrls: ['./donor-step.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, ObButtonDirective, TranslateModule, NgForOf],
})
export class OrgansAndTissueOverviewDialogComponent implements OnInit {
    private readonly organsAndTissueKeys: string[] = ["HEART", "LUNGS", "LIVER", "KIDNEYS", "SMALL_INTESTINE", "PANCREAS", "STOMACH", "EYE_CORNEA", "EYE_SCLERA", "HEART_VALVES", "BLOOD_VESSELS", "HEART_BAG"]
    public organsAndTissues: OrgansAndTissue[] = [];

    public ngOnInit(): void {
        this.organsAndTissues = this.organsAndTissueKeys.map(key => {
            return {
                title: `volitions.myChoiceStep.donor.organs.${key}.title`,
                description: `volitions.myChoiceStep.donor.organs.${key}.description`
            }
        })
    }
}

export interface OrgansAndTissue {
    title: string,
    description: string
}