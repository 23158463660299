import {Component, Input} from "@angular/core";
import {MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle} from "@angular/material/card";
import {NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatButton} from "@angular/material/button";
import {ObButtonDirective} from "@oblique/oblique";


@Component({
    selector: 'ogp-card-content',
    templateUrl: './ogp-card-content.component.html',
    styleUrl: './ogp-card-content.component.scss',
    standalone: true,
    imports: [
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatCardSubtitle,
        MatCardContent,
        NgIf,
        TranslateModule,
        MatButton,
        ObButtonDirective,
        NgClass,
    ]
})
export class OgpCardContentComponent {
    @Input() public cardTitle: string | null = null;
    @Input() public cardInformation: string | null = null;
    @Input() public hasButton?: boolean;
    @Input() public buttonLabel: string | null = null;
    @Input() public buttonClickAction!: () => void;

    public onButtonClick(): void {
        if(this.buttonClickAction) {
            this.buttonClickAction();
        }
    }
}