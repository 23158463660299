import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GetAssetService {
    private readonly assetBasePath = 'assets/images/';
    private readonly assetVolitionPath = 'assets/images/volition/';

    getIconBasePath(iconName: string): string {
        return `${this.assetBasePath}${iconName}.svg`;
    }
    getIconVolitionPath(iconName: string): string {
        return `${this.assetVolitionPath}${iconName}.svg`;
    }
}
