import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, tap } from "rxjs";
import {environment} from "../../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
      request: HttpRequest<string>,
      next: HttpHandler
  ): Observable<HttpEvent<string>> {
    if(request.url.startsWith(environment.volitionUrl) || request.url.startsWith(environment.volitionReadUrl)) {
      const token = sessionStorage.getItem('ogregToken');
      if (token) {
        request = this.addToken(request, token);
      }
    }

    return next.handle(request).pipe(tap(next => {
      if(next.type === 4) {
        if(next.status === 403) {
          sessionStorage.removeItem('ogregToken');
          sessionStorage.removeItem('loggedIn');
      }
    }}))
  }

  private addToken(request: HttpRequest<string>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
