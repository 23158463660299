import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import { HttpClient } from '@angular/common/http';
import {PersonDto, UserDataCompletedDto} from "@ogreg/shared";

@Injectable({
  providedIn: 'root'
})
export class PersonsService {
  model = 'persons';

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService
  ) {}

  savePerson(personDto: PersonDto): Observable<PersonDto> {
    return this.http.post<PersonDto>(
      `${this.env.ogPersonRegisterUrl}${this.model}`,
      personDto
    );
  }

  editPerson(personDto: PersonDto): Observable<PersonDto> {
    return this.http.put<PersonDto>(
      `${this.env.ogPersonRegisterUrl}${this.model}`,
      personDto
    );
  }

  getPersonDto(): Observable<PersonDto> {
    return this.http.get<PersonDto>(
      `${this.env.ogPersonRegisterUrl}${this.model}/me`
    );
  }

  isPersonalDataCompleted(): Observable<UserDataCompletedDto> {
    return this.http.get<UserDataCompletedDto>(
        `${this.env.ogPersonRegisterUrl}${this.model}/me/completed`)
  }
}
