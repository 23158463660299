import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';

export const PHONE_NUMBER_REGEXP =
    /^(0\d{0,19}|\(0\)\d{0,19}|\d{0,19})$/;
export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const trimmedInput = control.value.replace(/[\s()]/g, '');
      const isValid = PHONE_NUMBER_REGEXP.test(trimmedInput);
      if (isValid) {
        return null;
      }
      return phoneNumberValidatorResponse(false);
    } else  return null;
  };
}

function phoneNumberValidatorResponse(valid: boolean) {
  return {
    phoneNumberValidator: {
      valid
    }
  };
}

@Directive({
  selector: '[ogpPhoneNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneNumberValidatorDirective,
      multi: true
    }
  ],
  standalone: true
})
export class PhoneNumberValidatorDirective implements Validator {
  public validate(control: AbstractControl): ValidationErrors | null {
    return phoneNumberValidator()(control);
  }
}
