import { Injectable } from '@angular/core';
import { VolitionDto } from "../generated/model/modelsShared";
import {OgpStepperService} from "../shared/components/ogp-stepper/ogp-stepper.service";

@Injectable({
  providedIn: 'root'
})
export class VolitionService {

  constructor(private readonly ogpStepperService: OgpStepperService) {}

  public getNextMainVolitionStep() {
    // TODO: Save the StepData in the Browser here
    this.ogpStepperService.nextStep();
  }

  resetBodyPartsPerType(
    donorType: string | undefined,
    selectedBodyParts: string[] | undefined
  ) {
    if (donorType === 'FULL_DONOR' || donorType === 'NON_DONOR') {
      return [];
    }
    return selectedBodyParts;
  }

  buildNewVolition(
    volitionDto: VolitionDto | undefined,
    selectedDonorType: string | undefined,
    selectedBodyParts: string[] | undefined
  ): VolitionDto {
    const bodyPartIds = this.resetBodyPartsPerType(
      selectedDonorType,
      selectedBodyParts
    );
    return {
      id: volitionDto?.id,
      donorType: selectedDonorType ?? "NO TYPE CHOSEN",
      bodyPartIds,
      acceptedLegalText: true
    };
  }
}


export type DonorOption =  keyof typeof DonorOptionEnum;
export enum DonorOptionEnum {
  NON_DONOR = "NON_DONOR",
  DONOR = "DONOR",
  TRUSTED_PERSON = "TRUSTED_PERSON",
}

export type MainStep = keyof typeof MainStepEnum;
export enum MainStepEnum {
  DECISION_STEP,
  CONFIRMATION_STEP,
  STORAGE_STEP
}

export type SubStep = "donorOptionStep" | "myChoiceStep" | "viewTemplateStep"

export interface NavigationStepData {
  activeStep: MainStep,
  activeSubStep: SubStep,
  lastCompletedStep: SubStep | null,
  data?: any
}