import {Component, OnInit} from '@angular/core';
import {AuthConfig, OAuthService} from "angular-oauth2-oidc";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'ogp-redirect',
  templateUrl: './redirect.component.html',
  standalone: true,
  imports: [
  ]
})
export class RedirectComponent implements OnInit {

  authCodeFlowConfig: AuthConfig = {
    issuer: 'https://identity-eiam-r.eiam.admin.ch/realms/edi_bag-ogreg',


    // URL of the SPA to redirect the user to after login
    redirectUri: 'https://sso.ref.wsl-bag.ch/redirect',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: 'BAG-ogreg',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'openid',

    showDebugInformation: true,
  }

  constructor(private oauthService: OAuthService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.oauthService.configure(this.authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocument("https://sso.ref.wsl-bag.ch/assets/eiam_discovery.json").then(event => {
      this.route.queryParamMap.subscribe(params => {
        if(params.has("code")) {
          this.oauthService.tryLoginCodeFlow();
        } else {
          this.oauthService.initCodeFlow();
        }
      })
    })



  }

}
