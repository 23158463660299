<h2 mat-dialog-title *ngIf="data?.title" data-cy="change-email-address-popup-title">
  {{"personRegister.contactDetails.emailVerification.confirmationDialog.title" | translate}}
</h2>
<div mat-dialog-content>
  <p *ngIf="data?.content1" [innerHtml]="'personRegister.contactDetails.emailVerification.confirmationDialog.content1' | translate"></p>
  <p *ngIf="data?.content2" [innerHtml]="'personRegister.contactDetails.emailVerification.confirmationDialog.content2' | translate"></p>
</div>
<div mat-dialog-actions>
  <button type="button"
          cdkFocusInitial
          mat-button
          obButton="tertiary"
          mat-dialog-close
          (click)="dialogRef.close(false)"
          data-cy="change-email-address-popup-cancel-button">
    {{ "cancel"  | translate }}
  </button>
  <button type="button"
          cdkFocusInitial
          mat-button
          obButton="secondary"
          mat-dialog-close
          (click)="dialogRef.close(true)"
          data-cy="change-email-address-popup-confirm-button">
    {{ "confirm"  | translate }}
  </button>
</div>
