export const environment = {
    banner: {text: 'DEV'},
    personRegisterUrl:
        'https://person-register-be.dev.wsl-bag.ch/',
    volitionUrl:
        'https://public-be.dev.wsl-bag.ch/',
    volitionReadUrl: 'https://query-be.dev.wsl-bag.ch/',
    verificationUrl:
        'https://mail-verification-service.dev.wsl-bag.ch/',
    debugMode: true,
    appInsights: {
        instrumentationKey: 'ee804524-01b0-4cc9-a632-217299eaac42'
    },
    eiamLoginUrl: '',
    apiBaseURLs: [
        'https://person-register-be.dev.wsl-bag.ch/',
        'https://mail-verification-service.dev.wsl-bag.ch/'
    ]
};
