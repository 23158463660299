<div class="max-width-content">
    <div class="help-content">
        <h5>{{ "help.title" | translate }}</h5>
        <p class="fixed-width">
            {{ "help.text" | translate }}
        </p>
        <p>
            <a href="https://www.bit.admin.ch/bit/de/home.html">
                {{ "help.questionsAboutPersonalData.text" | translate }}
                <mat-icon class="ob-icon external-link-icon" svgIcon="external" />
            </a>
        </p>
        <p>
            <a href="https://www.leben-ist-teilen.ch/widerspruchsloesung/">
                {{ "help.lawChangeQuestions.text" | translate }}
                <mat-icon class="ob-icon external-link-icon" svgIcon="external" />
            </a>
        </p>
    </div>
</div>

