import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { HelpComponent } from '../help/help.component';
import { NgOptimizedImage } from '@angular/common';
import { HomeQuickAccessComponent } from './home-quick-access/home-quick-access.component';
import { HomeUserGuidanceComponent } from './home-user-guidance/home-user-guidance.component';

@Component({
  selector: 'ogp-home',
  templateUrl: './home.component.html',
  standalone: true,
  imports: [
    MatCardModule,
    TranslateModule,
    MatChipsModule,
    MatIcon,
    NgOptimizedImage,
    HomeQuickAccessComponent,
    HomeUserGuidanceComponent,
    HelpComponent
  ]
})
export class HomeComponent {

}
