<ogp-card-content [cardTitle]="'volitions.viewTemplateStep.title' | translate">
    <div class="watermarked">
        <h2 class="ob-screen-reader-only">Persönliche Daten</h2>
        <div class="ob-flex">
            <div>
                <p>
                    <b>{{ "volitions.viewTemplateStep.personRegisterData.creationDate" | translate }}</b>
                </p>
            </div>
            <div class="ob-grow-2">
                <p>{{ creationDate | date: 'dd.MM.yyyy' }}</p>
            </div>
        </div>
        <div class="ob-flex">
            <div>
                <p>
                    <b>{{ "volitions.viewTemplateStep.personRegisterData.personData" | translate }}</b>
                </p>
            </div>
            <div class="ob-grow-2">
                <p>{{ fullName }}</p>
                <p *ngIf="birthDay !== null">{{ "volitions.viewTemplateStep.personRegisterData.birthDay" | translate }}{{ birthDay | date: 'dd.MM.yyyy'}}</p>
                <p *ngIf="homeTown !== null">{{ "volitions.viewTemplateStep.personRegisterData.homeTown" | translate }}{{ homeTown }}</p>
                <p *ngIf="placeOfBirth !== null">{{ "volitions.viewTemplateStep.personRegisterData.placeOfBirth" | translate }}{{ placeOfBirth }}</p>
            </div>
        </div>
        <div *ngIf="eMail !== null || telephoneNumber !== null || mobileNumber !== null" class="ob-flex">
            <div>
                <p>
                    <b>{{ "volitions.viewTemplateStep.personRegisterData.contactData" | translate }}</b>
                </p>
            </div>
            <div class="ob-grow-2">
                <p *ngIf="eMail !== null">{{ eMail }}</p>
                <p *ngIf="telephoneNumber !== null">{{ telephoneNumber }}</p>
                <p *ngIf="mobileNumber !== null">{{ mobileNumber }}</p>
            </div>
        </div>
        <div class="ob-flex">
            <div>
                <p>
                    <b>{{ "volitions.viewTemplateStep.personRegisterData.contactForResearch.label" | translate }}</b>
                </p>
            </div>
            <div class="ob-grow-2">
                <p>{{ (acceptedLegalText === true
                    ?  "volitions.viewTemplateStep.personRegisterData.contactForResearch.yes"
                    :   "volitions.viewTemplateStep.personRegisterData.contactForResearch.no")  | translate }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="ogp-flex-block ogp-flex-block-align-horizontal-space-between">
            <div class="ogp-flex-block-horizontal-align-start">
                <h5>
                    {{ "volitions.viewTemplateStep.volitionData.title" | translate }}
                </h5>
            </div>
            <div class="ogp-flex-block-horizontal-align-end">
                <button (click)="onEditVolitionDetail()"
                        [matTooltip]="'volitions.viewTemplateStep.personRegisterData.editVolitionAnswerToolTip' | translate"
                        aria-label="Wille bearbeiten"
                        data-cy="change-volition-expression"
                        class="nowrap"
                        mat-button
                        obButton="secondary"
                        type="button">
                    {{ "volitions.viewTemplateStep.personRegisterData.editVolitionAnswer"  | translate }}
                </button>
            </div>
        </div>
        <div class="margin-y-m">
            <p class="medium-text">
                {{ "volitions.viewTemplateStep.volitionData.description" | translate }}
            </p>
        </div>
        <ogp-card-selection *ngIf="donorCardOption !== null"
                            [cardOption]="donorCardOption">
        </ogp-card-selection>
        <div class="margin-y-m">
            <p class="small-text" [innerHtml]="'volitions.viewTemplateStep.volitionData.information' | translate"></p>
        </div>
        <div class="margin-t-m">
            <p class="small-text margin-0">
                {{ "volitions.viewTemplateStep.volitionData.dataSecurityDescription.text" | translate }}
                <a href="https://www.bit.admin.ch"
                   target="_blank"
                   tabindex="0"
                   data-cy="data-security-regulatory">{{ "volitions.viewTemplateStep.volitionData.dataSecurityDescription.link" | translate }}</a>.
            </p>
        </div>
    </div>
</ogp-card-content>
<div class="ogp-flex-block ogp-flex-block-align-horizontal-end margin-t-l">
    <button mat-button
            obButton="primary"
            data-cy="cancel-process-button"
            type="button"
            (click)="confirmAndSubmit()">
        {{ "volitions.stepper.stepperAction.confirmAndSubmit" | translate }}
    </button>
</div>

