import { Injectable } from '@angular/core';
import { ObNotificationService } from '@oblique/oblique';
import { TranslatePipe } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ContactDetailsService {

  constructor(private readonly notificationService: ObNotificationService,
              private readonly translationPipe: TranslatePipe) { }

  showWarningNotification(title: string, message: string) {
    this.notificationService.warning(
      {
        title: this.translationPipe.transform(title),
        message: this.translationPipe.transform(message),
        timeout: 20000
      }
    );
  }
  getMergedTelephoneNumber(countryCode: string, telephoneNumber: string) {
    return (telephoneNumber === null)
      ? ''
      : (countryCode + this.removeLeadingZero(telephoneNumber.replace(/[\s()]/g, '')));
  }

  getMergedMobileNumber(countryCode: string, mobileNumber: string) {
    return (mobileNumber === null)
      ? ''
      : (countryCode + this.removeLeadingZero(mobileNumber.replace(/[\s()]/g, '')));
  }

  removeLeadingZero(str: string) {
    return (str.startsWith('0')) ? str.slice(1) : str;
  }
}
