import { Component, HostListener, OnInit } from '@angular/core';
import { PersonsService } from '../services/http-services/persons.service';
import { Observable, switchMap, tap } from 'rxjs';
import { ObButtonDirective } from '@oblique/oblique';
import { TranslateModule } from '@ngx-translate/core';
import { LoginService } from '@ogreg/ogreg-login';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { MailVerificationInformationDto, PersonDto } from "@ogreg/shared";
import { AuthService } from '../services/http-services/auth.service';
import { HelpComponent } from '../help/help.component';
import {
  UserGuidancePersonRegisterComponent
} from './user-guidance-person-register/user-guidance-person-register.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { EmailVerificationService } from "../services/http-services/email-verification.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'ogp-person-register',
  templateUrl: './person-register.component.html',
  styleUrl: './person-register.component.scss',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    TranslateModule,
    HelpComponent,
    UserGuidancePersonRegisterComponent,
    JsonPipe,
    PersonalDataComponent,
    MatButton,
    ObButtonDirective,
    MatIcon,
    MatSlideToggle,
    RouterLink,
    ContactDetailsComponent
  ]
})
export class PersonRegisterComponent implements OnInit {
  authPerson: PersonDto;
  person$: Observable<PersonDto> | undefined; // remove
  currentPerson: PersonDto | undefined;
  currentVerification: MailVerificationInformationDto | undefined;
  eIamPerson: PersonDto | null = null;
  userGuideOpenMd = false;
  userGuideOpenLg = true;
  isLargeScreen: boolean;

  constructor(
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly personsService: PersonsService,
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    private readonly emailVerificationService: EmailVerificationService
  ) {
    this.authPerson = this.loginService.getPerson();
    this.isLargeScreen = window.innerWidth > 905;
  }

  ngOnInit() {
    this.getPersonFromEIamToken();
    this.person$ = this.getPersonDto().pipe(tap((person) => this.currentPerson = person)); // remove
    this.fetchMailVerificationInformation();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; }) {
    // Update the boolean variable when window is resized
    this.isLargeScreen = event.target.innerWidth > 905;
    if (this.isLargeScreen) {
      this.userGuideOpenMd = false;
    }
  }

  onPersonEdited(personDto: PersonDto) {
    this.person$ = this.personsService.editPerson(personDto).pipe( // remove
      switchMap(() => this.getPersonDto()),
      tap(() => {
        if (personDto?.id) {
          this.authService.exchangeToken().subscribe(token => {
            if (token.token) {
              this.loginService.setOgregToken(token.token);
            }
          });
        }
      }),
      tap(() => {
        if(personDto.email !== this.currentPerson?.email) {
          this.currentVerification = {
            needsMailVerification: true,
            forMail: personDto.email
          };
        }

        this.currentPerson = personDto;
      }),
    );
  }

  onPersonCreated(personDto: PersonDto) {
    this.person$ = this.personsService.savePerson(personDto).pipe(
      switchMap(() => this.getPersonDto()),
      tap((personDto: PersonDto) => {
        if (personDto?.id) {
          this.authService.exchangeToken().subscribe(token => {
            if (token.token) {
              this.loginService.setOgregToken(token.token);
            }
          });
        }
      }),
      tap((newPerson) => {
        this.currentVerification = {
          needsMailVerification: true,
          forMail: personDto.email
        };

        this.currentPerson = newPerson
      })
    );
  }

  fetchMailVerificationInformation() {
    this.emailVerificationService.getCurrentVerification().subscribe((mailVerificationInformation) => {
      this.currentVerification = mailVerificationInformation;
    })
  }

  getPersonDto() {
    return this.personsService.getPersonDto();
  }

  getPersonFromEIamToken() {
    return this.http.get<PersonDto>(environment.personRegisterUrl + 'persons/me/eiam').subscribe((obs: PersonDto | null) => {
      this.eIamPerson = obs;
    });
  }

  toggleUserGuideMd() {
    this.userGuideOpenMd = (!this.userGuideOpenMd);
  }

  toggleUserGuideLg() {
    this.userGuideOpenLg = (!this.userGuideOpenLg);
  }

  onEmailReset(success: boolean) {
    if (success) {
      this.person$ = this.getPersonDto();
      this.currentVerification = undefined;
    }
  }
}
