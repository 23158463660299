<ogp-card-selection [cardOption]="donorCardOption">
</ogp-card-selection>
<div class="margin-t-l margin-b-15">
    {{"volitions.myChoiceStep.donor.donorInformation.prefix" | translate}}
    <a class="button-link"
       tabindex="0"
       data-cy="donor-information-dialog-link"
       (click)="openDialog()"
       (keydown)="onDonorInformationEnterKeyPressed($event)">
        {{"volitions.myChoiceStep.donor.donorInformation.link" | translate}}</a>{{"volitions.myChoiceStep.donor.donorInformation.suffix" | translate}}
</div>
<mat-radio-group [(ngModel)]="selectedDonorType"
                 (change)="onSelectionChange($event)"
                 data-cy="donor-radio-group">
    <mat-radio-button *ngFor="let donorItem of donorRadioItems"
                      [value]="donorItem.value"
                      [id]="donorItem.value"
                      [attr.data-cy]="'radio-item-' + donorItem.value">
        <div [innerHTML]="donorItem.label | translate"></div>
    </mat-radio-button>
</mat-radio-group>