import {ObButtonModule} from '@oblique/oblique';
import {MatInputModule} from '@angular/material/input';
import {Component, Input, OnInit} from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {OgpStepperService} from "./ogp-stepper.service";

@Component({
    selector: 'ogp-stepper',
    templateUrl: './ogp-stepper.component.html',
    styleUrls: ['./ogp-stepper.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        ObButtonModule,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        NgIf]
})
export class OgpStepperComponent implements OnInit{
    @Input({ required: true }) steps!: string[];
    protected currentStep = 0;

    constructor(private stepperService: OgpStepperService) {}

    ngOnInit(): void {
        // Subscribe to step changes from the service
        this.stepperService.currentStep$.subscribe((step) => {
            this.currentStep = step;
        });
    }
}