import {Component, EventEmitter, inject, OnInit, Output} from "@angular/core";
import {
    CardOption,
    OgpCardSelectionComponent
} from "../../../../shared/components/ogp-card-selection/ogp-card-selection.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {NgForOf} from "@angular/common";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {ObButtonDirective} from "@oblique/oblique";
import {FormsModule} from "@angular/forms";

@Component({
    selector: "ogp-donor-step",
    templateUrl: "donor-step.component.html",
    styleUrls: ['./donor-step.component.scss'],
    standalone: true,
    imports: [
        OgpCardSelectionComponent,
        TranslateModule,
        MatRadioGroup,
        MatRadioButton,
        NgForOf,
        FormsModule
    ]
})

export class DonorStepComponent implements OnInit{
    @Output() public donorSelectionChanged: EventEmitter<DonorType> = new EventEmitter<DonorType>();
    readonly dialog = inject(MatDialog);
    protected donorCardOption!: CardOption;
    protected donorRadioItems: DonorRadioItem[] = [
        {
            label: "volitions.myChoiceStep.donor.FULL_DONOR",
            value: "FULL_DONOR"
        },{
            label: "volitions.myChoiceStep.donor.PARTIAL_DONOR",
            value: "PARTIAL_DONOR"
        }
    ];
    protected selectedDonorType: DonorType | null = null;

    public ngOnInit(): void {
        this.donorCardOption = {
            cardTitleKey: `volitions.decision.DONOR.title`,
            cardInformationKey: `volitions.decision.DONOR.information`,
            headerIconNameKey: `volitions.decision.DONOR.headerIconName`,
            cardHoverColor: "light-blue",
            cardBackgroundColor: "light-blue",
            headerIconAlt: `volitions.decision.DONOR.headerIconAlt`,
            optionKey: "DONOR",
            marginClass: "margin-0",
            tabbable: false
        }
    }

    protected onSelectionChange(event: MatRadioChange): void {
        this.selectedDonorType = event.value;
        if(this.selectedDonorType != null) {
            this.donorSelectionChanged.emit(this.selectedDonorType);
        }
    }

    public openDialog() {
        this.dialog.open(OrgansAndTissueOverviewDialogComponent,  {
            width: '910px',
        });
    }

    public onDonorInformationEnterKeyPressed(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.openDialog();
        }
    }
}

export type DonorType = "FULL_DONOR" | "PARTIAL_DONOR"

export interface DonorRadioItem {
    label: string,
    value: DonorType
}

@Component({
    selector: 'ogp-organs-and-tissue-overview-dialog',
    templateUrl: 'organs-and-tissue-overview-dialog.html',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, ObButtonDirective, TranslateModule, NgForOf],
})
export class OrgansAndTissueOverviewDialogComponent implements OnInit{
    private readonly organsAndTissueKeys: string[] =["HEART", "LUNGS", "LIVER", "KIDNEY", "SMALL_INTESTINE", "PANCREAS", "EYE_CORNEA", "EYE_SCLERA", "HEART_VALVES", "BLOOD_VESSELS"]
    public organsAndTissues: OrgansAndTissue[] = [];

    public ngOnInit(): void {
        this.organsAndTissues = this.organsAndTissueKeys.map(key => {
            return {
                title: `volitions.myChoiceStep.donor.organsAndTissueDialog.${key}.title`,
                description: `volitions.myChoiceStep.donor.organsAndTissueDialog.${key}.description`
            }
        })
    }
}

export interface OrgansAndTissue {
    title: string,
    description: string
}