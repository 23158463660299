<mat-card [ngClass]="[hoverClass, marginClass, backgroundColor]"
          (click)="onCardClick()"
          (keydown)="onCardEnterKeyPressed($event)"
          tabindex="{{ tabindex }}">
    <mat-card-header>
        <div class="header-content">
            <div class="flex-box-80">
                <mat-card-title *ngIf="cardTitleKey !== null">
                    <h3>{{ cardTitleKey | translate }}</h3>
                </mat-card-title>
                <mat-card-subtitle *ngIf="cardInformationKey !== null">
                    <span [innerHTML]="cardInformationKey | translate"></span>
                </mat-card-subtitle>
            </div>
            <div *ngIf="iconPath"
                 class="avatar-content">
                <div mat-card-avatar>
                    <img [src]="iconPath" [alt]="headerIconAlt! | translate">
                </div>
            </div>
        </div>
    </mat-card-header>
</mat-card>