import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardContent } from "@angular/material/card";
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";
import { ObButtonDirective } from "@oblique/oblique";
import { TranslateModule } from "@ngx-translate/core";
import { UserCredentialsDto } from "@ogreg/shared";

@Component({
  selector: 'lib-ogreg-login',
  standalone: true,
  imports: [CommonModule, MatCard, MatCardContent, FormsModule, ReactiveFormsModule, MatInput, MatButton, ObButtonDirective, TranslateModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginLibComponent {
  @Output() authenticateRandom: EventEmitter<any> = new EventEmitter<any>();
  @Output() authenticate: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private readonly formBuilder: FormBuilder
  ) {}

  form = this.formBuilder.group({
    userName: ['', Validators.required],
    password: ['', Validators.required]
  });

  onAuthenticateRandom() {
    const credentials: UserCredentialsDto = {
      userName: 'random',
      password: 'random'
    };
    this.authenticateRandom.emit(credentials);
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    const credentials = this.form.value;
    this.authenticate.emit(credentials);
  }
}
