import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { JwtDto, UserCredentialsDto } from "@ogreg/shared";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  model = 'auth';

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService
  ) {}

  authenticate(userCredentialsDto: UserCredentialsDto): Observable<JwtDto> {
    return this.http.post<JwtDto>(
      `${this.env.ogPersonRegisterUrl}${this.model}`,
      userCredentialsDto
    );
  }

  authenticateRandom(
    userCredentialsDto: UserCredentialsDto
  ): Observable<JwtDto> {
    // delete authenticateRandom() function once eIAM login is implemented
    return this.http.post<JwtDto>(
      `${this.env.ogPersonRegisterUrl}${this.model}/random`,
      userCredentialsDto
    );
  }

  exchangeToken() {
    return this.http.get<JwtDto>(`${this.env.ogPersonRegisterUrl}auth/exchange`)
  }
}
