import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ObUnknownRouteModule } from '@oblique/oblique';
import { HomeComponent } from './home/home.component';
import { VolitionComponent } from './volition/volition.component';
import { authGuard } from './auth/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { PersonRegisterComponent } from './person-register/person-register.component';
import { isRegisteredGuard } from './auth/is-registered-guard.service';
import { FaqComponent } from "./faq/faq/faq.component";
import {environment} from "../environments/environment";
import {DebugComponent} from "./debug/debug.component";
import { DebugLoginComponent } from "./debug/debug-login/debug-login.component";
import {RedirectComponent} from "./redirect/redirect.component";

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'person-register',
    component: PersonRegisterComponent,
    canActivate: [authGuard]
  },
  {
    path: 'volition',
    component: VolitionComponent,
    canActivate: [authGuard, isRegisteredGuard]
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  ... environment.debugMode ?
    [
      {
        path: 'debug',
        component: DebugComponent
      },
      {
        path: 'debug-login',
        component: DebugLoginComponent
      }
    ] : [],
  { path: '**', redirectTo: 'unknown-route' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes), ObUnknownRouteModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
