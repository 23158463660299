import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ogp-home-user-guidance',
  standalone: true,
  imports: [CommonModule, MatCard, MatCardContent, MatCardHeader, MatCardTitle, MatIcon, TranslateModule],
  templateUrl: './home-user-guidance.component.html',
  styleUrl: './home-user-guidance.component.scss'
})
export class HomeUserGuidanceComponent {}
