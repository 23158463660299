import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {
    CardOption,
    HoverColor,
    OgpCardSelectionComponent
} from "../../../shared/components/ogp-card-selection/ogp-card-selection.component";
import {NgForOf} from "@angular/common";
import {OgpCardContentComponent} from "../../../shared/components/ogp-card-content/ogp-card-content.component";
import {DonorOption, NavigationStepData} from "../../volition.service";


@Component({
    selector: 'ogp-donor-option-step',
    templateUrl: './donor-option-step.component.html',
    standalone: true,
    imports: [
        TranslateModule,
        OgpCardSelectionComponent,
        NgForOf,
        OgpCardContentComponent,
    ]
})
export class DonorOptionStepComponent implements OnInit{
    @Output() activeStepChanged: EventEmitter<NavigationStepData> = new EventEmitter<NavigationStepData>();
    protected donorCardOptions!: CardOption[];
    protected donorOptions: DonorOption[] = ['NON_DONOR', 'DONOR', 'TRUSTED_PERSON'];

    public ngOnInit(): void {
        this.donorCardOptions = this.donorOptions.map(donorOption => {
            return {
                cardTitleKey: `volitions.decision.${donorOption}.title`,
                cardInformationKey: `volitions.decision.${donorOption}.information`,
                headerIconNameKey: `volitions.decision.${donorOption}.headerIconName`,
                cardHoverColor: this.getDonorOptionHoverColor(donorOption),
                headerIconAlt: `volitions.decision.${donorOption}.headerIconAlt`,
                optionKey: donorOption
            }
        } )
    }

    private getDonorOptionHoverColor(donorOption:DonorOption): HoverColor {
        switch (donorOption) {
            case "DONOR":
                return "light-blue";
            case "NON_DONOR":
                return "light-orange";
            case "TRUSTED_PERSON":
                return "light-green";
            default:
                return "none";
        }
    }

    handleCardSelection(optionKey: DonorOption): void {
        this.activeStepChanged.emit({
            activeStep: "DECISION_STEP",
            activeSubStep: "myChoiceStep",
            lastCompletedStep: "donorOptionStep",
            data: optionKey
        });
    }

}