<ogp-card-content [cardTitle]="'personRegister.contactDetails.title'"
                  [hasButton]="true"
                  [buttonLabel]="'personRegister.contactDetails.changeContactData'"
                  [buttonToolTip]="'personRegister.contactDetails.changeContactDateToolTip' | translate"
                  [buttonClickAction]="toggleEnableFormGroup"
                  [iconName]="'pen'"
                  [iconAtStart]="true"
                  [disabled]="(formGroup.enabled) || (currentVerification?.needsMailVerification ?? false)"
                  [cardClass]="'margin-t-xl'"
                  [hasActionButton]="true">
    <div class="margin-b-l" [innerHtml]="'personRegister.contactDetails.subtitle' | translate"></div>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2">
                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{ "personRegister.contactDetails.person.phonePrefix" | translate }}
                            </mat-label>
                            <mat-select formControlName="telephoneCode"
                                        data-cy="phonePrefix-select">
                                <mat-option [value]="phone_code"
                                            *ngFor="let phone_code of getPhoneCodes()"
                                            data-cy="telephoneCode-option">
                                    {{ phone_code }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{ "personRegister.contactDetails.person.telephoneNumber" | translate }}
                            </mat-label>
                            <input matInput
                                   formControlName="telephoneNumber"
                                   type="tel"
                                   ogpPhoneNumberValidator
                                   data-cy="telephoneNumber-input"/>
                            <mat-hint>
                                {{ (this.formGroup.get('telephoneCode')?.value === '+41') ? "Beispiel: 44 xxx xx xx" : "" }}
                            </mat-hint>
                            <mat-error *ngIf="this.formGroup.get('telephoneNumber')!.errors?.['phoneNumberValidator']">
                                Beispiel: 44 xxx xx xx
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "personRegister.contactDetails.person.phonePrefix" | translate }}</mat-label>
                            <mat-select formControlName="mobileCode"
                                        data-cy="mobileCode-select">
                                <mat-option [value]="phone_code"
                                            *ngFor="let phone_code of getPhoneCodes()"
                                            data-cy="mobileCode-option">
                                    {{ phone_code }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{ "personRegister.contactDetails.person.mobileNumber" | translate }}
                            </mat-label>
                            <input matInput
                                   formControlName="mobileNumber"
                                   type="tel"
                                   ogpPhoneNumberValidator
                                   data-cy="mobileNumber-input"/>
                            <mat-hint>
                                {{ (this.formGroup.get('mobileCode')?.value === '+41') ? "Beispiel: 79 xxx xx xx" : "" }}
                            </mat-hint>
                            <mat-error *ngIf="this.formGroup.get('mobileNumber')!.errors?.['phoneNumberValidator']">
                                Beispiel: 79 xxx xx xx
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row custom-padding">
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "personRegister.contactDetails.person.email" | translate }}</mat-label>
                            <input matInput formControlName="email" ogpEmailValidator data-cy="email-input" />
                            <mat-error *ngIf="this.formGroup.get('email')!.errors?.['emailValidator']">
                                {{ "notifications.invalidEmailFormat" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "personRegister.contactDetails.person.emailRepeat" | translate }}</mat-label>
                            <input matInput formControlName="emailRepeat" ogpEmailValidator data-cy="emailRepeat-input" />
                            <mat-error *ngIf="this.formGroup.get('emailRepeat')!.errors?.['mailNotTheSame']">
                                {{ "personRegister.contactDetails.confirmationMailNotTheSame" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <input type="checkbox" formControlName="acceptedLegalText" id="acceptedLegalText" class="form-check-input"
                               [ngClass]="{ 'is-invalid': formSubmitted && this.formGroup.get('acceptedLegalText')!.errors?.['required'] }"
                               data-cy="acceptedLegalText-checkbox"/>
                        <label for="acceptedLegalText"
                               [ngClass]="{ 'is-invalid': formSubmitted && this.formGroup.get('acceptedLegalText')!.errors?.['required'] }">
                            {{ "personRegister.contactDetails.agreedToReceiveEmailsText" | translate }}
                        </label>
                    </div>
                </div>
                <div class="row" *ngIf="currentVerification?.needsMailVerification">
                    <div class="ob-span-12 custom-padding">
                        <ogp-email-verification (emailReset)="onEmailReset($event)"></ogp-email-verification>
                    </div>
                </div>
            </div>
    </form>
    <ng-container action-button-content>
        <div class="ogp-flex-block ogp-flex-block-align-horizontal-end ogp-flex-block-wrap">
            <button class="ogp-flex-block-items-row-gap ogp-flex-block-items-column-gap"
                    mat-button
                    obButton="tertiary"
                    [disabled]="!this.formGroup.dirty"
                    (click)="reset()"
                    data-cy="reset-button">
                {{ "reset" | translate }}
            </button>
            <button type="submit"
                    mat-button
                    (click)="onSubmit()"
                    [disabled]="!this.formGroup.dirty"
                    obButton="primary"
                    autofocus
                    data-cy="save-button">
                {{ "saveAndVerify" | translate }}
            </button>
        </div>
    </ng-container>
</ogp-card-content>
<div id="scroll-to-contact-details"></div>