import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf} from "@angular/common";
import {ObButtonDirective} from "@oblique/oblique";

@Component({
    selector: 'ogp-cancel-alert-dialog',
    templateUrl: 'alert-dialog.component.html',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, TranslateModule, NgIf, ObButtonDirective],
})
export class AlertDialogComponent{
    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        titleKey: string;
        textKey: string;
        confirmButtonKey: string | null;
        continueButtonKey: string | null;
    }) {}
}